import { api } from './';
import providesTagsHelper from './helpers/providesTags';

export default function courseEndpoints(builder) {
  return {
    getCoursesByTrainers: builder.query({
      query: (trainers = []) =>
        `/courses?searchable=true&trainers=${trainers
          .map(({ value }) => value)
          .toString()}`,
      providesTags: (result) => providesTagsHelper({ type: 'Course', result }),
    }),
    getCourses: builder.query({
      query: (args) => {
        const search = new URLSearchParams({
          ...(args?.type && { type: args.type }),
        }).toString();

        return `/courses?${search}`;
      },
      providesTags: (result) => providesTagsHelper({ type: 'Course', result }),
    }),
    getCoursesForLookup: builder.query({
      query: () => '/courses/lookup',
    }),
    getCourse: builder.query({
      query: (id) => ({
        url: `/courses/${id}`,
      }),
      providesTags: (result) =>
        result ? [{ type: 'Course', id: result._id }] : [],
    }),
    addCourse: builder.mutation({
      query: ({ name, type }) => ({
        url: '/courses',
        method: 'POST',
        body: { name, type },
      }),
      invalidatesTags: ['Course'],
    }),
    deleteCourse: builder.mutation({
      query: (id) => ({
        url: `/courses/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Course'],
    }),
    updateCourse: builder.mutation({
      query: (course) => ({
        url: `/courses/${course._id}`,
        method: 'PUT',
        body: course,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Course', id: arg.course },
      ],
      // this onQueryStarted func optimistically updates the results of getCourse when updateCourse is called
      // if the network call fails, it reverts the results to the previous state
      async onQueryStarted({ _id, ...update }, { dispatch, queryFulfilled }) {
        const updateResult = dispatch(
          api.util.updateQueryData('getCourse', _id, (draft) => {
            Object.assign(draft, update);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          updateResult.undo();
        }
      },
    }),
    addCourseModule: builder.mutation({
      query: (module) => ({
        url: '/modules/create',
        method: 'POST',
        body: module,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Course', id: arg.course },
      ],
    }),
    updateCourseSectionModules: builder.mutation({
      query: ({ id, sectionModule }) => ({
        url: `/courses/${id}/section_modules/${sectionModule.id}`,
        method: 'POST',
        body: sectionModule,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Course', id: arg.id }],
      // this onQueryStarted func optimistically updates the results of getCourse when updateCourseSectionModules is called
      // if the network call fails, it reverts the results to the previous state
      async onQueryStarted(
        { id, sectionModule },
        { dispatch, queryFulfilled }
      ) {
        const updateResult = dispatch(
          api.util.updateQueryData('getCourse', id, (course) => {
            const sectionIndex = course.modules.findIndex(
              ({ module_id: { _id } }) => _id === sectionModule.id
            );
            if (sectionIndex >= 0) {
              const section = course.modules[sectionIndex];
              course.modules.splice(sectionIndex, 1, {
                ...section,
                modules: sectionModule.modules,
              });
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          updateResult.undo();
        }
      },
    }),
    getFileModule: builder.query({
      query: (id) => `/FileModule/${id}`,
      providesTags: (result) =>
        result ? [{ type: 'FileModule', id: result._id }] : [],
    }),
  };
}
