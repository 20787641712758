import {
  createSlice,
  // createSelector
} from '@reduxjs/toolkit';

import { api, providesTagsHelper } from 'app/api';

export const apiWithPartners = api.injectEndpoints({
  addTagTypes: ['Partner', 'PartnerTrainer', 'PartnerProvider'],
  endpoints: (builder) => ({
    getPartners: builder.query({
      query: () => `/partners`,
      providesTags: (result) => providesTagsHelper({ type: 'Partner', result }),
    }),
    getPartnersProviders: builder.query({
      query: ({ partnerType = 'All Partners', fullName, country, region }) => {
        const params = new URLSearchParams({
          partnerType,
          ...(fullName && fullName.length && { fullName }),
          ...(country && { country }),
          ...(region && { region }),
        });
        return `/partners/providers?${params.toString()}`;
      },
    }),
    getPartnersTrainers: builder.query({
      query: ({ partnerType = 'All Partners', fullName, country }) => {
        const params = new URLSearchParams({
          partnerType,
          ...(fullName && fullName.length && { fullName }),
          ...(country && { country }),
        });
        return `/partners/trainers?${params.toString()}`;
      },
    }),
    getPartner: builder.query({
      query: (id) => `/partners/${id}`,
      providesTags: (result) =>
        result ? [{ type: 'Partner', id: result._id }] : [],
    }),
    createPartner: builder.mutation({
      query: (partner) => ({
        url: '/partners',
        method: 'POST',
        body: partner,
      }),
      invalidatesTags: ['Partner'],
    }),
    updatePartner: builder.mutation({
      query: ({ id, updates }) => ({
        url: `/partners/${id}`,
        method: 'POST',
        body: updates,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Partner', id: arg.id },
      ],
      async onQueryStarted({ id, updates }, { dispatch, queryFulfilled }) {
        const updateResult = dispatch(
          api.util.updateQueryData('getPartner', id, (draft) => {
            Object.assign(draft, updates);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          updateResult.undo();
        }
      },
    }),
    deletePartner: builder.mutation({
      query: (id) => ({
        url: `/partners/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Partner'],
    }),
  }),
});

export const {
  useGetPartnersQuery,
  useGetPartnersProvidersQuery,
  useGetPartnersTrainersQuery,
  useGetPartnerQuery,
  useCreatePartnerMutation,
  useUpdatePartnerMutation,
  useDeletePartnerMutation,
} = apiWithPartners;

const initialState = {
  deletePartner: {
    isOpen: false,
    partner: null,
  },
  directoryFilters: {
    country: null,
    region: null,
    name: '',
    partnerType: { value: 'All Partners', label: 'All Partners' },
  },
};

export const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    toggleDeletePartner: (state, { payload: { isOpen, partner } }) => {
      if (isOpen) {
        state.deletePartner.isOpen = true;
        state.deletePartner.partner = partner;
      } else {
        state.deletePartner.isOpen = false;
        state.deletePartner.partner = null;
      }
    },
    setDirectoryFilterCountry: (state, { payload = null }) => {
      state.directoryFilters.country = payload;
    },
    setDirectoryFilterRegion: (state, { payload = null }) => {
      state.directoryFilters.region = payload;
    },
    setDirectoryFilterName: (state, { payload = '' }) => {
      state.directoryFilters.name = payload;
    },
    setDirectoryFilterPartnerType: (
      state,
      { payload = initialState.directoryFilters.partnerType }
    ) => {
      state.directoryFilters.partnerType = payload;
    },
    resetDirectoryFilters: (state) => {
      state.directoryFilters = initialState.directoryFilters;
    },
  },
});

export const {
  toggleDeletePartner,
  setDirectoryFilterCountry,
  setDirectoryFilterName,
  setDirectoryFilterPartnerType,
  setDirectoryFilterRegion,
  resetDirectoryFilters,
} = partnersSlice.actions;

export default partnersSlice.reducer;

export const selectDeletePartner = (state) => state.partners.deletePartner;
export const selectDirectoryFilters = (state) =>
  state.partners.directoryFilters;
