import { api } from './';
import providesTagsHelper from './helpers/providesTags';

export default function sessionEndpoints(builder) {
  return {
    getSessions: builder.query({
      query: (query) => `/sessions${query ? `?${query}` : ''}`,
      providesTags: (result) => providesTagsHelper({ type: 'Session', result }),
    }),
    createSession: builder.mutation({
      query: (session) => ({
        url: '/sessions',
        method: 'POST',
        body: session,
      }),
      invalidatesTags: ['Session'],
    }),
    getSession: builder.query({
      query: (id) => `/sessions/${id}`,
      providesTags: (result) =>
        result ? [{ type: 'Session', id: result._id }] : [],
    }),
    updateSession: builder.mutation({
      query: (session) => ({
        url: `/sessions/${session._id}`,
        method: 'POST',
        body: session,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Session', id: arg._id },
      ],
    }),
    removeSession: builder.mutation({
      query: ({ id, cancellationReason = null }) => ({
        url: `/sessions/remove/${id}`,
        method: 'POST',
        body: {
          cancellationReason,
        },
      }),
      invalidatesTags: ['Session'],
    }),
    addSessionContent: builder.mutation({
      query: (id) => ({
        url: `/sessions/add_content/${id}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Session', id: arg }],
    }),
    removeSessionContent: builder.mutation({
      query: ({ sessionId, moduleType, moduleId }) => ({
        url: `/sessions/${sessionId}/${moduleType}/${moduleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Session', id: arg.sessionId },
      ],
    }),
    unlockSessionContent: builder.mutation({
      query: ({ id, content: { unlocked, module } }) => ({
        url: `/sessions/unlock_content/${id}`,
        method: 'POST',
        body: {
          unlocked,
          module,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Session', id: arg.id },
      ],
      async onQueryStarted(
        { id, content: { unlocked, module } },
        { dispatch, queryFulfilled }
      ) {
        const updateResult = dispatch(
          api.util.updateQueryData('getSession', id, (draft) => {
            const unlockedModules = unlocked
              ? [...draft.unlocked_modules, module]
              : draft.unlocked_modules.filter((m) => m !== module);
            Object.assign(draft, {
              unlocked_modules: unlockedModules,
            });
          })
        );
        try {
          await queryFulfilled;
        } catch {
          updateResult.undo();
        }
      },
    }),
    removeUserFromSession: builder.mutation({
      query: ({ sessionId, userId, reason = 'unknown' }) => ({
        url: `/sessions/remove_user_from_session/${sessionId}/${userId}`,
        method: 'POST',
        body: { reason },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Session', id: arg.sessionId },
      ],
    }),
    setSessionComplete: builder.mutation({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}/complete`,
        method: 'POST',
      }),
    }),
  };
}
