export const hasLocalStorage = () => {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

// optional obj boolean param so that we can choose to return the object with value and expiry
export const getWithExpiry = (key, returnObj = false) => {
  if (hasLocalStorage()) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) return returnObj ? { value: null, expiry: undefined } : null;
    // atob converts from base 64 encoding back to string
    const item = JSON.parse(atob(itemStr));
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      if (key === 'scrumToken') {
        localStorage.removeItem('token');
      }
      return returnObj ? { value: null, expiry: item.expiry } : null;
    }
    return returnObj ? item : item.value;
  }
};

// 1000ms * 60s * 60m = 1 hour
const ONE_HOUR = 1000 * 60 * 60;

// defaults to 1 hour expiration
export const setWithExpiry = (key, value, ttl = ONE_HOUR) => {
  if (hasLocalStorage()) {
    const now = new Date();
    const item = {
      value,
      expiry: now.getTime() + ttl,
    };
    // btoa converts string to base 64 encoding
    // we don't know if it's necessary to do this, but we didn't like the idea of being able to see the expiry time and easiy manipilate it by editing localStorage
    localStorage.setItem(key, btoa(JSON.stringify(item)));
  }
};

export const loadState = (key) => {
  try {
    if (hasLocalStorage()) {
      const state = localStorage.getItem(key);
      if (state === null) {
        return undefined;
      }
      try {
        return JSON.parse(state);
      } catch {
        return state;
      }
    } else {
      return undefined;
    }
  } catch (err) {
    return undefined;
  }
};

export const saveState = (key, state) => {
  try {
    if (hasLocalStorage()) {
      if (typeof state === 'string') {
        localStorage.setItem(key, state);
      } else {
        const serialState = JSON.stringify(state);
        localStorage.setItem(key, serialState);
      }
    }
  } catch (err) {
    console.log(err);
  }
};
