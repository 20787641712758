export default function admin(builder) {
  return {
    mergeUsers: builder.mutation({
      query: ({
        preferredUsername,
        secondaryUsernames,
        preferredCompanyUserId,
      }) => ({
        url: '/scripts/users/merge-accounts',
        method: 'POST',
        body: {
          preferredUsername,
          secondaryUsernames,
          preferredCompanyUserId,
        },
      }),
      invalidatesTags: ['User'],
    }),
    getSessionKPIs: builder.query({
      query: (params) => ({
        url: '/reporting/sessionKpi',
        params,
      }),
      providesTags: ['SessionKpiReport'],
    }),
    getRenewalsTransactions: builder.query({
      query: () => ({
        url: '/reporting/renewal_transactions',
        responseHandler: (response) => response.text(),
      }),
    }),
    getTrainerAndSessionTotals: builder.query({
      query: () => `/reporting/trainer_and_session_totals`,
    }),
    getAdminData: builder.query({
      query: () => `/reporting/admin_data`,
    }),
    getTrainerListData: builder.query({
      query: () => `/reporting/trainer_list_data`,
    }),
    getRenewalExperimentResults: builder.query({
      query: ({ name, group }) => `/reporting/experiments/${name}/${group}`,
    }),
    getRenewalCharts: builder.query({
      query: (params) => ({
        url: '/reporting/renewals/charts',
        params,
      }),
      keepUnusedDataFor: 0,
    }),
    getRenewalKpis: builder.query({
      query: (params) => ({
        url: '/reporting/renewals/kpis',
        params,
      }),
      keepUnusedDataFor: 0,
    }),
  };
}
