import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from 'hooks/useAuth';

export function PrivateRoute({ children, roles = [], ...rest }) {
  const { user } = useAuth();

  const companyRole = user?.companies.length && user.companies[0].role;

  if (
    user &&
    roles.length &&
    !roles.includes(user.role) &&
    !roles.includes(companyRole)
  ) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.arrayOf(PropTypes.string),
  isActive: PropTypes.bool,
};

export default PrivateRoute;
