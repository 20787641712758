import { createSlice } from '@reduxjs/toolkit';

import { api } from 'app/api';

// todo: migrate the rest of the sessions endpoints here from src/app/api
export const apiWithSessions = api.injectEndpoints({
  endpoints: (builder) => ({
    validateUsers: builder.mutation({
      query: ({ sessionId, users = [] }) => ({
        url: `/sessions/${sessionId}/users/validate`,
        method: 'POST',
        body: { users },
      }),
    }),
    bulkAddUsers: builder.mutation({
      query: ({ sessionId, users = [] }) => ({
        url: `/users/bulkAddUsersToSession/${sessionId}`,
        method: 'POST',
        body: { users },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Session', id: arg.sessionId },
      ],
    }),
  }),
});

export const { useValidateUsersMutation, useBulkAddUsersMutation } =
  apiWithSessions;

const initialState = {
  session: null,
  isAddingSession: false,
  isAddingUser: false,
  removeSession: {
    isOpen: false,
  },
  removeUser: {
    isOpen: false,
    userId: null,
  },
  bulkAdd: {
    isOpen: false,
    list: {
      existingUsers: false,
      newUsers: false,
      invalidUsers: false,
    },
    stage: 'input',
    students: [],
    rawInput: '',
    parsedInput: '',
    error: '',
    columnMap: {
      fullName: null,
      firstName: 0,
      lastName: 1,
      email: 2,
    },
  },
};

export const sessionsSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    toggleModal: (state, { payload: { type, value } }) => {
      if (typeof state[type] === 'boolean') {
        state[type] = typeof value === 'boolean' ? value : !state[type];
      }
    },
    setSession: (state, { payload = null }) => {
      state.session = payload;
    },
    toggleRemoveSession: (state, { payload: { isOpen } }) => {
      state.removeSession.isOpen = !!isOpen || false;
    },
    toggleRemoveUser: (state, { payload: { isOpen, userId = null } }) => {
      state.removeUser.isOpen = !!isOpen || false;
      state.removeUser.userId = userId;
    },
    toggleBulkAdd: (state, { payload: { isOpen } }) => {
      state.bulkAdd.isOpen = !!isOpen || false;
    },
    setBulkAddStudents: (state, { payload = [] }) => {
      state.bulkAdd.students = payload;
    },
    setBulkAddStage: (state, { payload = 'input' }) => {
      if (['input', 'summary', 'mapColumns'].includes(payload)) {
        state.bulkAdd.stage = payload;
        state.bulkAdd.error = '';
      }
    },
    resetBulkAdd: (state) => {
      state.bulkAdd = {
        isOpen: false,
        stage: 'input',
        students: [],
        rawInput: '',
        parsedInput: '',
        error: '',
        list: {
          existingUsers: false,
          newUsers: false,
          invalidUsers: false,
        },
        columnMap: {
          fullName: null,
          firstName: 0,
          lastName: 1,
          email: 2,
        },
      };
    },
    toggleBulkAddSection: (state, { payload: { list, isOpen } }) => {
      if (list && typeof isOpen === 'boolean') {
        state.bulkAdd.list[list] = isOpen;
      }
    },
    setBulkAddError: (state, { payload = '' }) => {
      state.bulkAdd.error = payload;
    },
    setRawInput: (state, { payload = '' }) => {
      state.bulkAdd.rawInput = payload;
    },
    setParsedInput: (state, { payload = '' }) => {
      state.bulkAdd.parsedInput = payload;
    },
    setColumnMap: (state, { payload: { column, index } }) => {
      state.bulkAdd.columnMap[column] = index;
    },
  },
});

export const {
  resetBulkAdd,
  setBulkAddError,
  setBulkAddStage,
  setBulkAddStudents,
  setRawInput,
  setParsedInput,
  setSession,
  toggleBulkAdd,
  toggleBulkAddSection,
  toggleModal,
  toggleRemoveSession,
  toggleRemoveUser,
  setColumnMap,
} = sessionsSlice.actions;

export default sessionsSlice.reducer;

export const selectSession = (state) => state.sessions.session;
export const selectIsAddingSession = (state) => state.sessions.isAddingSession;
export const selectIsAddingUser = (state) => state.sessions.isAddingUser;
export const selectRemoveSession = (state) => state.sessions.removeSession;
export const selectRemoveUser = (state) => state.sessions.removeUser;
export const selectBulkAdd = (state) => state.sessions.bulkAdd;
export const selectBulkAddStudents = (state) => state.sessions.bulkAdd.students;
export const selectBulkAddColumnMap = (state) =>
  state.sessions.bulkAdd.columnMap;
