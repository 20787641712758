import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ROOT_URL } from 'App';

import { logoutUser, setCredentials } from 'features/auth/slice';
import providesTagsHelper from './helpers/providesTags';
import admin from './admin';
import auth from './auth';
import companies from './companies';
import courses from './courses';
import credentials from './credentials';
import groups from './groups';
import invoices from './invoices';
import quizzes from './quiz';
import ratings from './ratings';
import renewals from './renewals';
import sessions from './sessions';
import teams from './teams';
import users from './users';
import fileModules from './fileModules';
import surveyModules from './surveyModules';
import htmlModules from './htmlModules';
import videoModules from './videoModules';
import quizModules from './quizModules';

// re-exporting to lazily handle places where we are importing from here
export { providesTagsHelper };

const baseQuery = fetchBaseQuery({
  baseUrl: ROOT_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set('authorization', `${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshResult = await baseQuery('/auth', api, extraOptions);
    if (refreshResult.data) {
      // store the new token
      api.dispatch(setCredentials(refreshResult.data));
      // retry the initial query
      return await baseQuery(args, api, extraOptions);
    }
    api.dispatch(logoutUser());
  }
  return result;
};

const getEndpoints = (builder) => {
  const endpointImports = [
    admin,
    auth,
    companies,
    courses,
    credentials,
    groups,
    invoices,
    quizzes,
    ratings,
    renewals,
    sessions,
    teams,
    users,
    fileModules,
    surveyModules,
    htmlModules,
    videoModules,
    quizModules,
  ];
  return endpointImports.reduce(
    (endpoints, fn) => ({ ...endpoints, ...fn(builder) }),
    {}
  );
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Achievement',
    'AuthUser',
    'Company',
    'CompanyTeam',
    'CompanyUser',
    'Course',
    'FileModule',
    'Group',
    'HTMLModule',
    'InviteCode',
    'Invoice',
    'InvoiceEdit',
    'Partner',
    'PokerRoom',
    'Quiz',
    'QuizModule',
    'QuizModuleQuestion',
    'Report',
    'Session',
    'SessionKpiReport',
    'SurveyModule',
    'SurveyResponse',
    'Team',
    'Trainer',
    'User',
    'UserCourse',
    'UserProgress',
    'VideoModule',
  ],
  endpoints: getEndpoints,
});

export const {
  endpoints,
  // auth endpoints
  useImpersonateUserMutation,
  useLazyGetAuthUserQuery,
  useLazyValidateRecaptchaQuery,
  useLoginMutation,
  useAdminResetPasswordMutation,
  useResetPasswordMutation,
  useUpdateUserPasswordMutation,
  useGetResetTokenMutation,
  useUpdateUserInfoMutation,
  useUpdateUserPhotoMutation,
  useUpdateUserFieldMutation,
  useSignUpMutation,
  useUpdateUserLanguageMutation,
  // session endpoints
  useGetSessionsQuery,
  useLazyGetSessionsQuery,
  useCreateSessionMutation,
  useGetSessionQuery,
  useUpdateSessionMutation,
  useRemoveSessionMutation,
  useAddSessionContentMutation,
  useRemoveSessionContentMutation,
  useUnlockSessionContentMutation,
  useRemoveUserFromSessionMutation,
  useAddUserToSessionMutation,
  useSetSessionCompleteMutation,
  // client/group endpoints
  useGetGroupsQuery,
  useCreateGroupMutation,
  // trainer endpoints
  useAddTrainerMutation,
  // course endpoints
  useGetCoursesByTrainersQuery,
  useGetCoursesQuery,
  useGetCoursesForLookupQuery,
  useGetCourseQuery,
  useAddCourseMutation,
  useAddCourseModuleMutation,
  useUpdateCourseMutation,
  useDeleteCourseMutation,
  useUpdateCourseSectionModulesMutation,
  // file module endpoints
  useGetFileModuleQuery,
  // survey module endpoints
  useGetSurveyModuleQuery,
  useUpdateSurveyModuleQuestionMutation,
  useAddSurveyModuleQuestionMutation,
  useEditSurveyModuleQuestionMutation,
  // rating endpoint
  useCreateInstructorRatingMutation,
  useMergeUsersMutation,
  // credential endpoint
  useGetCredentialTypesQuery,
  // quiz endpoints
  useGetQuizQuery,
  useSelectAnswerMutation,
  useSubmitQuizMutation,
  // user endpoints
  useRegisterCourseMutation,
  // team endpoints
  useAssignTeamCourseQuery,
  useListTeamUsersQuery,
  useGetTeamQuery,
  useLazyGetTeamExportQuery,
  useAssignUsersCourseMutation,
  useUnassignUsersCourseMutation,
  // company endpoints
  useListCompaniesQuery,
  useGetCompanyQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useUpdateCompanyCourseMutation,
  useDeleteCompanyMutation,
  useGetCompanyTeamsQuery,
  useListCompanyUsersQuery,
  useLazyGetCompanyReportQuery,
  useAddCompanyTeamMutation,
  useAddTeamCourseMutation,
  useDeleteCompanyTeamMutation,
  useUpdateTeamCourseSeatsMutation,
  useAddCompanyUserMutation,
  useRemoveCompanyUserMutation,
  useGetCompanyUserQuery,
  useUpdateCompanyUserMutation,
  useGetExamAnalyisQuery,
  useLazyGetExamAnalyisQuery,
  useTransferUsersTeamMutation,
  useAddCompanyCourseMutation,
  useGetCoursesListQuery,
} = api;

export default api;
