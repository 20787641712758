import { createSlice } from '@reduxjs/toolkit';
import { api } from 'app/api';

export const apiWithFeedback = api.injectEndpoints({
  endpoints: (builder) => ({
    // todo: add feedback endpoints
    getFeedback: builder.query({
      query: ({ trainerId, sessionId }) => {
        const params = new URLSearchParams({
          ...(trainerId && { trainerId }),
          ...(sessionId && { sessionId }),
        });
        return {
          url: `/feedback?${params}`,
          method: 'GET',
        };
      },
    }),
    getStudentFeedback: builder.query({
      query: ({ page, sessionId, trainerId }) => {
        const params = new URLSearchParams({
          ...(trainerId && { trainerId }),
          ...(sessionId && { sessionId }),
          page: page - 1, // offset by 1 since page starts at 0 on backend
        });
        return {
          url: `/feedback/get_student_feedback?${params}`,
          method: 'GET',
        };
      },
    }),
    getTrainerSessions: builder.query({
      query: (trainerId) => {
        const params = new URLSearchParams({
          ...(trainerId && { trainerId }),
        });
        return `/feedback/get_trainer_sessions?${params}`;
      },
    }),
  }),
});

export const {
  useGetFeedbackQuery,
  useGetStudentFeedbackQuery,
  useGetTrainerSessionsQuery,
} = apiWithFeedback;

const initialState = {
  filteredSessionId: {
    label: 'All Classes',
    value: null,
  },
  studentsFeedbackPage: 1,
  cotrainingFeedbackPage: 1,
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setStudentsFeedbackPage: (state, { payload: page = 1 }) => {
      state.studentsFeedbackPage = page;
    },
    setCotrainingFeedbackPage: (state, { payload: page = 1 }) => {
      state.cotrainingFeedbackPage = page;
    },
    setFilteredSessionId: (
      state,
      { payload: sessionId = initialState.filteredSessionId }
    ) => {
      state.filteredSessionId = sessionId;
    },
  },
});

export const actions = feedbackSlice.actions;

export default feedbackSlice.reducer;

export const selectStudentsFeedbackPage = (state) =>
  state.feedback.studentsFeedbackPage;
export const selectCotrainingFeedbackPage = (state) =>
  state.feedback.studentsFeedbackPage;
export const selectFilteredSessionId = (state) =>
  state.feedback.filteredSessionId;
