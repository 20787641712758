import providesTags from './helpers/providesTags';

export default function quizModules(builder) {
  return {
    getQuizModule: builder.query({
      query: ({ id, locale = 'en' }) => ({
        url: `/QuizModule/${id}?locale=${locale}`,
      }),
      providesTags: (result) => providesTags({ type: 'QuizModule', result }),
    }),
    updateQuizModule: builder.mutation({
      query: ({ id, ...updates }) => ({
        url: `/QuizModule/${id}`,
        method: 'PUT',
        body: updates,
      }),
      invalidatesTags: ['QuizModule'],
    }),
    getQuestion: builder.query({
      query: ({ quizModuleId, questionId, locale }) => ({
        url: `/QuizModule/${quizModuleId}/questions/${questionId}${
          locale !== 'en' ? `/${locale}` : ''
        }`,
      }),
      providesTags: (result) =>
        providesTags({ type: 'QuizModuleQuestion', result }),
    }),
    addQuestion: builder.mutation({
      query: (quizModuleId) => ({
        url: `/QuizModule/${quizModuleId}/questions`,
        method: 'POST',
      }),
      invalidatesTags: ['QuizModule'],
    }),
    updateQuestion: builder.mutation({
      query: ({ quizModuleId, questionId, question }) => ({
        url: `/QuizModule/${quizModuleId}/questions/${questionId}`,
        method: 'PUT',
        body: question,
      }),
      invalidatesTags: ['QuizModuleQuestion', 'QuizModule'],
    }),
    removeQuestion: builder.mutation({
      query: ({ quizModuleId, questionId, questionPool }) => ({
        url: `/QuizModule/${quizModuleId}/questions/${questionId}`,
        method: 'DELETE',
        body: questionPool,
      }),
      invalidatesTags: ['QuizModule'],
    }),
    addQuestionLocale: builder.mutation({
      query: ({ quizModuleId, questionId, locale }) => ({
        url: `/QuizModule/${quizModuleId}/questions/${questionId}/${locale}`,
        method: 'POST',
      }),
      invalidatesTags: ['QuizModuleQuestion'],
    }),
  };
}
