import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import 'airbnb-browser-shims';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import * as Sentry from '@sentry/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import './App.css';
import theme from 'app/theme';
import { useLazyGetAuthUserQuery } from 'app/api';
import { setUser } from 'features/auth/slice';
import { usePageViews } from 'hooks/usePageViews';
import Loading from './components/Loading';
import Router from './router';

import { setLocale } from 'actions/interface';

const isProduction = process.env.REACT_APP_ENV === 'production';
export const ROOT_URL = process.env.REACT_APP_ROOT_URL;

if (isProduction) {
  ReactGA.initialize('UA-147627649-3');
}

const App = () => {
  usePageViews();
  const dispatch = useDispatch();
  const { token, user, isImpersonating } = useSelector((state) => state.auth);
  const locale = useSelector((state) => state.ui.locale);
  const [getAuthUser, { data, isFetching }] = useLazyGetAuthUserQuery({
    refetchOnReconnect: true,
  });
  const { i18n } = useTranslation();

  // on load, fetch the user using the token
  useEffect(() => {
    if (!user && token) getAuthUser();
  }, [getAuthUser, token, user]);

  // once the user data loaded, set the user in redux
  useEffect(() => {
    if (!isFetching && data) {
      dispatch(setUser({ user: data }));
    }
  }, [data, isFetching, dispatch]);

  useEffect(() => {
    // exception for tw because of our mix of scrumlab-translations using 'tw' but i18next needing it to be 'zh-TW'
    // updates the locale in redux if it's not the same as  the user's preferred language
    const language = user?.language === 'tw' ? 'zh-TW' : user?.language;
    if (user && locale !== language) {
      dispatch(setLocale(user.language));
      i18n.changeLanguage(language);
    }
    // set or clear the user in the Sentry context/scope so we can identify the user in issues
    if (isProduction) {
      if (user) {
        Sentry.setUser({ id: user._id, username: user.username });
      } else {
        Sentry.configureScope((scope) => scope.setUser(null));
      }
    }
    // if intercom is loaded, either clear the intercom user (if logged out) or set the user (if logged in)
    // if the user is being impersonated by an admin, block intercom from capturing the user settings
    if (window?.Intercom) {
      if (user && !isImpersonating) {
        window.Intercom('boot', {
          app_id: 'nbkd2of5',
          name: user?.full_name,
          created_at: user?.created_at,
          user_id: user?._id,
          email: user?.username,
          user_hash: user?.hash,
        });
      } else {
        window.Intercom('boot', {
          app_id: 'nbkd2of5',
        });
      }
    }
  }, [dispatch, i18n, locale, user, isImpersonating]);

  if (isFetching) {
    return <Loading />;
  }

  return (
    <div
      style={{
        backgroundColor:
          window.self === window.top
            ? theme.palette.secondary.lightGray
            : '#FFFFFF',
      }}
    >
      <ThemeProvider theme={theme}>
        <Router />
        <ToastContainer
          position="top-right"
          autoClose={2500}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          limit={1}
        />
      </ThemeProvider>
    </div>
  );
};

export default App;
