import { createSlice } from '@reduxjs/toolkit';
import { api } from 'app/api';

export const apiWithReports = api.injectEndpoints({
  endpoints: (builder) => ({
    getRenewalReport: builder.query({
      query: () => `/reporting/renewal_data`,
      providesTags: [{ type: 'Report', id: 'renewal' }],
    }),
    getAllStudentData: builder.mutation({
      query: () => ({
        url: `/reporting/all_student_data`,
        method: 'GET',
      }),
    }),
    submitCoTrainingFeedback: builder.mutation({
      query: (body) => ({
        url: `/renewals/renewal_feedback`,
        method: 'POST',
        body,
      }),
    }),
    getRenewalTrainerFeedback: builder.mutation({
      query: (body) => ({
        url: `/renewals/renewal_trainer_feedback`,
        method: 'GET',
      }),
    }),
    submitExperienceReport: builder.mutation({
      query: (body) => ({
        url: `/renewals/xp_report`,
        method: 'POST',
        body,
      }),
    }),
    uploadCaseStudyFile: builder.mutation({
      query: (body) => ({
        url: `/FileModule/upload`,
        method: 'POST',
        body,
      }),
    }),
    requestTrainerRenewal: builder.mutation({
      query: (body) => ({
        url: `/renewals/request_trainer_renewal`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetRenewalReportQuery,
  useRequestTrainerRenewalMutation,
  useSubmitCoTrainingFeedbackMutation,
  useGetRenewalTrainerFeedbackMutation,
  useSubmitExperienceReportMutation,
  useGetAllStudentDataMutation,
  useUploadCaseStudyFileMutation,
} = apiWithReports;

const initialState = {
  search: '',
};

export const trainersSlice = createSlice({
  name: 'trainers',
  initialState,
  reducers: {
    setSearch: (state, { payload: search }) => {
      if (typeof search === 'string') {
        state.search = search;
      }
    },
  },
});

export const actions = trainersSlice.actions;

export const { setSearch } = trainersSlice.actions;

export default trainersSlice.reducer;

export const selectSearch = (state) => state.trainers.search;
