import React from 'react';
import PropTypes from 'prop-types';
import Spinkit from 'react-spinkit';

const Loading = ({ fadeIn }) => {
  return (
    <div>
      <Spinkit
        fadeIn={fadeIn ? fadeIn : 'half'}
        name="cube-grid"
        color="#cc0000"
        className="Absolute-Center"
        data-cy="loading-spinner"
      />
    </div>
  );
};

Loading.propTypes = {
  fadeIn: PropTypes.string,
};

export default Loading;
