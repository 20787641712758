import React, { useEffect, lazy, Suspense, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Container, withStyles } from '@material-ui/core';
import { useSize } from 'ahooks';

import { AppBarOffsetContext } from './navContext';
import Loading from 'components/Loading';
import Login from 'features/auth/Login';
import PrivateRoute from 'utils/PrivateRoute';
import importRetry from 'utils/importRetry';
import ScrollToTop from './ScrollToTop';
import styles from './router.styles';

// lazy loading all the components
// https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
const Header = lazy(() => importRetry(() => import('features/common/Header')));
const Footer = lazy(() => importRetry(() => import('../components/Footer')));
const AuthBanner = lazy(() =>
  importRetry(() => import('../components/AuthBanner'))
);
const RenewalExamBanner = lazy(() =>
  importRetry(() => import('features/common/banners/RenewalExamBanner'))
);
const StudentDashboard = lazy(() =>
  importRetry(() => import('features/student/Dashboard'))
);
const TrainerDashboard = lazy(() =>
  importRetry(() => import('features/trainers/Dashboard'))
);
const Courses = lazy(() =>
  importRetry(() => import('features/courses/Courses'))
);
const EditCourse = lazy(() =>
  importRetry(() => import('features/courses/EditCourse'))
);
const EditQuizModule = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/QuizModule/EditQuizModule')
  )
);

const EditSurveyModule = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/SurveyModule/EditSurveyModule')
  )
);
const QuizModule = lazy(() =>
  importRetry(() => import('features/courses/ViewModule/QuizModule'))
);
const Sessions = lazy(() =>
  importRetry(() => import('features/sessions/Sessions'))
);
const Session = lazy(() =>
  importRetry(() => import('features/sessions/Session'))
);
const Groups = lazy(() => importRetry(() => import('pages/Groups')));
const Group = lazy(() => importRetry(() => import('pages/Groups/Group')));
const Users = lazy(() => importRetry(() => import('features/users/Users')));
const User = lazy(() => importRetry(() => import('features/users/User')));
const SelfSignup = lazy(() =>
  importRetry(() => import('features/auth/SelfSignUp'))
);
const ViewCourse = lazy(() =>
  importRetry(() => import('features/courses/ViewCourse'))
);
const OnDemandModule = lazy(() =>
  importRetry(() => import('features/courses/ViewModule'))
);
const ViewSession = lazy(() =>
  importRetry(() => import('features/sessions/ViewSession'))
);
const RequestResetToken = lazy(() =>
  importRetry(() => import('features/auth/RequestResetToken'))
);
const ResetPassword = lazy(() =>
  importRetry(() => import('features/auth/ResetPassword'))
);
const UserSettings = lazy(() =>
  importRetry(() => import('pages/UserSettings'))
);
const EditFileModule = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/FileModule/EditFileModule')
  )
);
// todo: once we merge the func of nested modules to be used on trainer led courses, we can likely remove these view module comps
const ViewFileModule = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/FileModule/ViewFileModule')
  )
);
const ViewVideoModule = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/VideoModule/ViewVideoModule')
  )
);
const ViewHTMLModule = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/HTMLModule/ViewHTMLModule')
  )
);

const EditVideoModule = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/VideoModule/EditVideoModule')
  )
);
const EditHTMLModule = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/HTMLModule/EditHTMLModule')
  )
);
const TypeformModule = lazy(() =>
  importRetry(() => import('features/courses/ViewModule/TypeformModule'))
);
const AdminViewResults = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/QuizModule/AdminViewResults')
  )
);
const EditQuestion = lazy(() =>
  importRetry(() =>
    import('features/courses/EditModule/QuizModule/EditQuestion')
  )
);
const Achievements = lazy(() =>
  importRetry(() => import('features/achievements/Achievements'))
);
const FindClasses = lazy(() => importRetry(() => import('pages/FindClasses')));
const Partners = lazy(() =>
  importRetry(() => import('features/partners/Partners'))
);
const ExamAnalysis = lazy(() =>
  importRetry(() => import('features/examAnalysis/Results'))
);
const AdminDashboard = lazy(() =>
  importRetry(() => import('features/admin/Dashboard'))
);
const CredentialLookup = lazy(() =>
  importRetry(() => import('features/credentialLookup'))
);
const BillingDashboard = lazy(() =>
  importRetry(() => import('features/billing/BillingDashboard'))
);
const InvoicePage = lazy(() =>
  importRetry(() => import('features/billing/InvoicePage'))
);
const SuccessfulPaymentPage = lazy(() =>
  importRetry(() => import('features/billing/SuccessfulPayment'))
);
const Trainer = lazy(() => importRetry(() => import('pages/Trainers/Trainer')));
const EditInvoice = lazy(() =>
  importRetry(() => import('features/billing/EditInvoicePage'))
);
const Partner = lazy(() =>
  importRetry(() => import('features/partners/Partner'))
);
const FAQPage = lazy(() => importRetry(() => import('pages/FAQ/FAQ')));
const Feedback = lazy(() => importRetry(() => import('features/feedback')));
const PartnersDirectory = lazy(() =>
  importRetry(() => import('features/partners/Directory'))
);
const PokerDashboard = lazy(() =>
  importRetry(() => import('features/poker/Dashboard'))
);
const JoinPokerRoom = lazy(() =>
  importRetry(() => import('features/poker/JoinRoom'))
);
const PokerRoom = lazy(() => importRetry(() => import('features/poker/Room')));
const CompaniesList = lazy(() =>
  importRetry(() => import('features/companies/List'))
);
const CompanyDashboard = lazy(() =>
  importRetry(() => import('features/companies/Dashboard'))
);
const TeamDashboard = lazy(() =>
  importRetry(() => import('features/teams/Dashboard'))
);
const SurveyResponsesDashboard = lazy(() =>
  importRetry(() => import('features/surveyResponses/Dashboard'))
);

export const history = createBrowserHistory();

const Router = ({ classes }) => {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const headerRef = useRef(null);
  const size = useSize(headerRef.current);

  const isIFrame = window.self !== window.top;

  // when location changes, capture the current location as a custom tag in Sentry to make it searchable
  useEffect(() => {
    Sentry.setTag('location', location.pathname);
  }, [location]);

  return (
    <AppBarOffsetContext.Provider value={size?.height || 0}>
      <ScrollToTop>
        <div ref={headerRef}>
          {user && !isIFrame && (
            <>
              <AuthBanner />
              <RenewalExamBanner />
              <Header />
            </>
          )}
        </div>
        <Container maxWidth={false} className={classes.root}>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/billing/:id">
                <SuccessfulPaymentPage />
              </Route>
              <Route exact path="/provider-lookup">
                <PartnersDirectory />
              </Route>
              <Route exact path="/credential-lookup">
                <CredentialLookup />
              </Route>
              <Route exact path="/self-signup">
                <SelfSignup />
              </Route>
              <Route exact path="/self-signup/:id">
                <SelfSignup />
              </Route>
              <Route exact path="/frequently-asked-questions">
                <FAQPage />
              </Route>
              <Route exact path="/reset">
                <RequestResetToken />
              </Route>
              <Route exact path="/reset/:token">
                <ResetPassword />
              </Route>
              <Route exact path="/find-class">
                <FindClasses />
              </Route>
              <Route exact path="/invoice/:id">
                <InvoicePage />
              </Route>
              <PrivateRoute exact path="/admin" roles={['admin']}>
                <AdminDashboard />
              </PrivateRoute>
              <PrivateRoute exact path="/courses">
                <Courses />
              </PrivateRoute>
              <PrivateRoute exact path="/courses/:search">
                <Courses />
              </PrivateRoute>
              <PrivateRoute exact path="/course/:id" roles={['admin']}>
                <EditCourse />
              </PrivateRoute>
              <PrivateRoute exact path="/partners" roles={['admin']}>
                <Partners />
              </PrivateRoute>
              <PrivateRoute exact path="/partner/:id" roles={['admin']}>
                <Partner />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/course/:courseId/QuizModule/:id"
                roles={['admin']}
              >
                <EditQuizModule />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/module/QuizModule/:id"
                roles={['admin']}
              >
                <EditQuizModule />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/course/:courseId/SurveyModule/:moduleId"
              >
                <EditSurveyModule />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/course/:courseId/FileModule/:moduleId"
                roles={['admin', 'trainer', 'assistant']}
              >
                <EditFileModule />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/module/FileModule/:moduleId"
                roles={['admin', 'trainer', 'assistant']}
              >
                <EditFileModule />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/course/:courseId/VideoModule/:id"
                roles={['admin', 'trainer', 'assistant']}
              >
                <EditVideoModule />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/module/VideoModule/:id"
                roles={['admin', 'trainer', 'assistant']}
              >
                <EditVideoModule />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/course/:courseId/HTMLModule/:id"
                roles={['admin']}
              >
                <EditHTMLModule />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/module/HTMLModule/:id"
                roles={['admin']}
              >
                <EditHTMLModule />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/course/:courseId/QuizModule/:quizModuleId/question/:questionId/:locale"
                roles={['admin']}
              >
                <EditQuestion />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/exam-analysis"
                roles={['admin', 'trainer']}
              >
                <ExamAnalysis />
              </PrivateRoute>
              <PrivateRoute exact path="/trainer/:id" roles={['admin']}>
                <Trainer />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/view/QuizModule/results/:quiz_id/:session"
                roles={['admin', 'trainer', 'assistant']}
              >
                <AdminViewResults />
              </PrivateRoute>
              <PrivateRoute exact path="/view/QuizModule/:id">
                <QuizModule />
              </PrivateRoute>
              <PrivateRoute exact path="/view/QuizModule/:id/:session">
                <QuizModule />
              </PrivateRoute>
              <PrivateRoute exact path="/view/FileModule/:id/:session">
                <ViewFileModule />
              </PrivateRoute>
              <PrivateRoute exact path="/view/VideoModule/:id/:session">
                <ViewVideoModule />
              </PrivateRoute>
              <PrivateRoute exact path="/view/HTMLModule/:id/:session">
                <ViewHTMLModule />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/view/TypeformModule/:moduleId/:sessionId/:typeformId"
              >
                <TypeformModule />
              </PrivateRoute>
              <PrivateRoute exact path="/view/achievements">
                <Achievements />
              </PrivateRoute>
              <PrivateRoute exact path="/view/achievements/:achievementName">
                <Achievements />
              </PrivateRoute>
              <PrivateRoute exact path="/view/course/:courseId">
                <ViewCourse />
              </PrivateRoute>
              <PrivateRoute exact path="/view/course/:courseId/:id">
                <OnDemandModule />
              </PrivateRoute>
              <PrivateRoute exact path="/view/session/:id">
                <ViewSession />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/sessions"
                roles={['admin', 'trainer', 'assistant']}
              >
                <Sessions />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/sessions/:search"
                roles={['admin', 'trainer', 'assistant']}
              >
                <Sessions />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/session/:id"
                roles={['admin', 'trainer', 'assistant']}
              >
                <Session />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/groups"
                roles={['admin', 'trainer', 'assistant']}
              >
                <Groups />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/group/:id"
                roles={['admin', 'trainer', 'assistant']}
              >
                <Group />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/users"
                roles={['admin', 'trainer', 'assistant']}
              >
                <Users />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/users/:searchTerms"
                roles={['admin', 'trainer', 'assistant']}
              >
                <Users />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/user/:id"
                roles={['admin', 'trainer', 'assistant']}
              >
                <User />
              </PrivateRoute>
              <PrivateRoute exact path="/settings">
                <UserSettings />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/billing"
                roles={['admin', 'trainer', 'assistant']}
              >
                <BillingDashboard />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/billing/:id"
                roles={['admin', 'trainer', 'assistant']}
              >
                <BillingDashboard />
              </PrivateRoute>
              <PrivateRoute exact path="/edit-invoice/:id" roles={['admin']}>
                <EditInvoice />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/my-feedback"
                roles={['admin', 'trainer']}
              >
                <Feedback />
              </PrivateRoute>
              <PrivateRoute exact path="/companies" roles={['admin']}>
                <CompaniesList />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/companies/:companyId"
                roles={['admin', 'companyAdmin']}
              >
                <CompanyDashboard />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/companies/:companyId/exams"
                roles={['admin', 'companyAdmin']}
              >
                <ExamAnalysis />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/companies/:companyId/teams/:teamId"
                roles={['admin', 'companyAdmin', 'teamAdmin']}
              >
                <TeamDashboard />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/companies/:companyId/teams/:teamId/exams"
                roles={['admin', 'companyAdmin', 'teamAdmin']}
              >
                <ExamAnalysis />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/trainer"
                roles={['admin', 'trainer', 'assistant']}
              >
                <TrainerDashboard />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/student"
                roles={['admin', 'trainer', 'assistant', 'student']}
              >
                <StudentDashboard />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/student/:inviteCode"
                roles={['admin', 'trainer', 'assistant', 'student']}
              >
                <StudentDashboard />
              </PrivateRoute>
              <PrivateRoute exact path="/poker">
                <PokerDashboard />
              </PrivateRoute>
              <Route exact path="/poker/join">
                <JoinPokerRoom />
              </Route>
              <Route exact path="/poker/room/:code">
                <PokerRoom />
              </Route>
              <PrivateRoute exact path="/surveyResponses" roles={['admin']}>
                <SurveyResponsesDashboard />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/companies/:companyId/surveyResponses"
                roles={['admin', 'companyAdmin']}
              >
                <SurveyResponsesDashboard />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/companies/:companyId/teams/:teamId/surveyResponses"
                roles={['admin', 'companyAdmin', 'teamAdmin']}
              >
                <SurveyResponsesDashboard />
              </PrivateRoute>
              {/* this is the fallback, so if they end up at a bogus address, it'll send them to the dashboard */}
              <Redirect to="/student" />
            </Switch>
          </Suspense>
        </Container>
        {user && !isIFrame && <Footer />}
      </ScrollToTop>
    </AppBarOffsetContext.Provider>
  );
};

Router.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Router);
