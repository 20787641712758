import { createSlice, createSelector } from '@reduxjs/toolkit';

import { selectOnDemandCourses, selectAchievements } from 'features/auth/slice';

const initialState = {
  // course page / modules pages
  course: null,
  currentModuleId: null,
  drawerOpen: true,
  expanded: null,
  quizHasResults: false,
  quizResultsDialogOpen: false,
  gradedQuiz: null,
  // courses page
  courseList: [],
  search: '',
  isAddingCourse: false,
  isRemovingCourse: false,
  isAddingQuestion: false,
  addModule: {
    isOpen: false,
    isSection: false,
    section: null,
  },
  removeSection: {
    isOpen: false,
    section: null,
  },
  editSection: {
    isOpen: false,
    section: null,
  },
  removeQuestionModule: {
    isOpen: false,
    questionId: null,
  },
  editedQuestions: [],
  editQuizModule: {
    addingQuestion: false,
    locale: {
      label: 'English',
      value: 'en',
    },
  },
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    // course / modules reducers
    setCourse: (state, { payload }) => {
      state.course = payload || null;
    },
    setCurrentModuleId: (state, { payload = null }) => {
      state.currentModuleId = payload;
    },
    setModule: (state, { payload }) => {
      state.module = payload || null;
    },
    setExpanded: (state, { payload }) => {
      state.expanded = payload || null;
    },
    toggleDrawer: (state, { payload = true }) => {
      state.drawerOpen = payload;
    },
    toggleQuizHasResults: (state, { payload = false }) => {
      state.quizHasResults = payload;
    },
    toggleQuizResultsDialogOpen: (state, { payload = false }) => {
      state.quizResultsDialogOpen = payload;
    },
    setGradedQuiz: (state, { payload = null }) => {
      state.gradedQuiz = payload;
    },
    // courses reducers
    toggleModal: (state, { payload: { type, value } }) => {
      if (typeof state[type] === 'boolean') {
        state[type] = typeof value === 'boolean' ? value : !state[type];
      }
    },
    setCourseList: (state, { payload }) => {
      state.courseList = payload || [];
    },
    setSearch: (state, { payload }) => {
      state.search = payload || '';
    },
    toggleAddModule: (
      state,
      { payload: { isOpen, isSection = false, section = null } }
    ) => {
      if (isOpen) {
        state.addModule.isOpen = true;
        state.addModule.isSection = isSection;
        state.addModule.section = section;
      } else {
        state.addModule.isOpen = false;
        state.addModule.isSection = false;
        state.addModule.section = null;
      }
    },
    toggleRemoveSection: (state, { payload: { isOpen, section } }) => {
      if (isOpen) {
        state.removeSection.isOpen = true;
        state.removeSection.section = section;
      } else {
        state.removeSection.isOpen = false;
        state.removeSection.section = null;
      }
    },
    toggleEditSection: (state, { payload: { isOpen, section } }) => {
      if (isOpen) {
        state.editSection.isOpen = true;
        state.editSection.section = section;
      } else {
        state.editSection.isOpen = false;
        state.editSection.section = null;
      }
    },
    toggleRemoveQuestionModule: (
      state,
      { payload: { isOpen, questionId } }
    ) => {
      if (isOpen) {
        state.removeQuestionModule.isOpen = true;
        state.removeQuestionModule.questionId = questionId;
      } else {
        state.removeQuestionModule.isOpen = false;
        state.removeQuestionModule.questionId = null;
      }
    },
    setEditedQuestions: (state, { payload }) => {
      state.editedQuestions = payload;
    },
    setQuizModuleLocale: (
      state,
      { payload = { label: 'English', value: 'en' } }
    ) => {
      state.editQuizModule.locale = payload;
    },
    setQuizModuleAddingQuestion: (state, { payload = false }) => {
      state.editQuizModule.addingQuestion = payload;
    },
  },
});

export const {
  setCourse,
  setCurrentModuleId,
  setModule,
  setExpanded,
  toggleDrawer,
  toggleQuizHasResults,
  toggleQuizResultsDialogOpen,
  setGradedQuiz,
  setCourseList,
  setSearch,
  toggleModal,
  toggleAddModule,
  toggleRemoveSection,
  toggleEditSection,
  toggleRemoveQuestionModule,
  setEditedQuestions,
  setQuizModuleLocale,
  setQuizModuleAddingQuestion,
} = coursesSlice.actions;

export default coursesSlice.reducer;

// course / modules selectors
export const selectCourse = (state) => state.courses.course;
export const selectCurrentModuleId = (state) => state.courses.currentModuleId;
export const selectCourseId = (state) => state.courses.course?._id;
export const selectExpanded = (state) => state.courses.expanded;
export const selectDrawerOpen = (state) => state.courses.drawerOpen;
export const selectQuizHasResults = (state) => state.courses.quizHasResults;
export const selectQuizResultsDialogOpen = (state) =>
  state.courses.quizResultsDialogOpen;
export const selectGradedQuiz = (state) => state.courses.gradedQuiz;

// courses selectors
export const selectSearch = (state) => state.courses.search;
export const selectCourseList = (state) => state.courses.courseList;
export const selectIsAddingCourse = (state) => state.courses.isAddingCourse;
export const selectIsRemovingCourse = (state) => state.courses.isRemovingCourse;
export const selectAddModule = (state) => state.courses.addModule;
export const selectRemoveSection = (state) => state.courses.removeSection;
export const selectEditSection = (state) => state.courses.editSection;
export const selectRemoveQuestionModule = (state) =>
  state.courses.removeQuestionModule;
export const selectEditedQuestions = (state) => state.courses.editedQuestions;
export const selectIsAddingQuestion = (state) => state.courses.isAddingQuestion;

// createSelector memoizes the results of the selectors automatically to help reduce unncessary re-renders
// and improve performance of expensive calculations to get derived state from the store
// https://redux.js.org/usage/deriving-data-selectors#createselector-overview

// selects the course from the auth user's on_demand_courses using the current course id
// we need this to derive some other state below
export const selectCourseById = createSelector(
  selectOnDemandCourses,
  selectCourseId,
  (courses, idToFind) =>
    // eslint-disable-next-line camelcase
    courses.find(({ course_id }) => course_id._id === idToFind) || {}
);

export const selectQuizId = createSelector(
  selectCourseById,
  (course) => course.quiz_id || null
);

export const selectCurrentSectionId = createSelector(
  selectCourse,
  selectCurrentModuleId,
  (course, currentModuleId) =>
    course?.modules?.find(({ module_id: { modules } }) =>
      modules.some((submodule) => submodule.module_id._id === currentModuleId)
    ) || null
);

// selects the cert url from the auth user's achievements by getting the current onDemand course id then finding the cert url by the course name
export const selectCertUrl = createSelector(
  selectAchievements,
  selectCourseById,
  (achievements, course) =>
    achievements?.find(({ name }) => name === course?.name)?.cert_url || null
);

export const selectProgress = createSelector(
  selectCourseById,
  (course) => course.progress || []
);

export const selectSubmodules = createSelector(
  selectCourse,
  (course) =>
    course?.modules?.map(({ module_id: { modules } }) => modules)?.flat() || []
);

export const selectRequiredSubmodules = createSelector(
  selectSubmodules,
  (submodules) => submodules.filter(({ module_id: { required } }) => required)
);

export const selectCurrentModule = createSelector(
  selectCurrentModuleId,
  selectSubmodules,
  (currentModuleId, submodules) => {
    if (currentModuleId) {
      return submodules.find(
        ({ module_id: { _id } }) => _id === currentModuleId
      );
    } else {
      return submodules.length ? submodules[0] : null;
    }
  }
);

export const selectCurrentModuleIndex = createSelector(
  selectCurrentModuleId,
  selectSubmodules,
  (currentModuleId, submodules) =>
    submodules.findIndex(({ module_id: { _id } }) => _id === currentModuleId)
);

export const selectProgressPercentage = createSelector(
  selectRequiredSubmodules,
  selectProgress,
  (submodules, progress) => {
    const totalCompleted = progress.filter(({ completed }) => completed).length;
    return submodules.length > 0
      ? Math.round((totalCompleted / submodules.length) * 100)
      : 100;
  }
);

export const selectReadyForFinalExam = createSelector(
  selectProgressPercentage,
  (percentage) => percentage > 80
);

export const selectModuleProgress = createSelector(
  selectProgress,
  selectCurrentModuleId,
  (progress, moduleId) =>
    // eslint-disable-next-line camelcase
    progress.find(({ module_id }) => module_id === moduleId)
);

export const selectEditQuizModule = (state) => state.courses.editQuizModule;
