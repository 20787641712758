import { createSlice, createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

import { getWithExpiry, loadState } from 'utils/localStorage';
import calculateProgress from 'utils/helpers/getProgressPercentage';
import bifurcateBy from 'utils/helpers/bifurcateBy';

const initialState = {
  user: null,
  // load the token from localStorage if it exists
  scrumToken: getWithExpiry('scrumToken'),
  token: loadState('token') || null,
  progress: null,
  loading: false,
  error: null,
  signingIn: false,
  pending: null,
  isImpersonating: !!getWithExpiry('scrumToken'),
  rememberMe: loadState('rememberMe') || false,
  rememberedEmail: loadState('rememberedEmail') || null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload: { user, token } }) => {
      state.user = user;
      state.token = token;
      state.error = null;
    },
    setUser: (state, { payload: { user } }) => {
      state.user = user;
      state.error = null;
    },
    setUserPhoto: (state, { payload }) => {
      state.user = { ...state.user, image: payload };
    },
    startImpersonating: (state, { payload: { user, token } }) => {
      state.scrumToken = state.token;
      state.user = user;
      state.token = token;
      state.isImpersonating = true;
      state.error = null;
    },
    stopImpersonating: (state) => {
      state.user = null;
      state.token = state.scrumToken;
      state.scrumToken = null;
      state.isImpersonating = false;
      state.error = null;
    },
    logoutUser: (state) => {
      state.user = null;
      state.token = null;
      state.scrumToken = null;
      state.isImpersonating = false;
      state.signingIn = false;
      state.error = null;
    },
    setError: (state, { payload }) => {
      state.error = payload || null;
    },
    addRating: (state, { payload }) => {
      state.user.ratings = [...state.user.ratings, payload];
    },
    toggleOptIn: (state, { payload: fieldName }) => {
      if (typeof state?.user[fieldName] === 'boolean') {
        state.user[fieldName] = !state.user[fieldName];
      }
    },
    setProgress: (state, { payload: { progress } }) => {
      state.user.progress = progress;
    },
    setSessions: (state, { payload: { sessions } }) => {
      state.user.sessions = sessions;
    },
    setOnDemandCourse: (state, { payload: course }) => {
      const index = state.user?.on_demand_courses?.findIndex(
        // eslint-disable-next-line camelcase
        ({ _id }) => _id === course._id
      );
      if (index === -1) state.user.on_demand_courses.push(course);
      else state.user.on_demand_courses[index] = course;
    },
    setModules: (state, { payload: modules }) => {
      if (state.user?.modules) {
        state.user.modules = modules;
      }
    },
    toggleRememberMe: (state, { payload: rememberMe = false }) => {
      state.rememberMe = rememberMe;
    },
    setRememberedEmail: (state, { payload: email }) => {
      state.rememberedEmail = state.rememberMe ? email : null;
    },
    setUserLanguage: (state, { payload: language }) => {
      state.user.language = language;
    },
  },
});

export const {
  addRating,
  logoutUser,
  setCredentials,
  setError,
  setModules,
  setOnDemandCourse,
  setProgress,
  setSessions,
  setRememberedEmail,
  setUser,
  setUserPhoto,
  setUserLanguage,
  startImpersonating,
  stopImpersonating,
  toggleOptIn,
  toggleRememberMe,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;

export const selectProgress = createSelector(
  selectCurrentUser,
  (user) => user?.progress || {}
);

export const selectOnDemandCourses = createSelector(
  selectCurrentUser,
  (user) => user?.on_demand_courses || []
);

export const selectTeamCourses = createSelector(
  selectCurrentUser,
  (user) => user?.teamCourses || []
);

export const selectSessions = createSelector(
  selectCurrentUser,
  (user) => user?.sessions || []
);

export const selectBifurcatedSessions = createSelector(
  selectCurrentUser,
  (user) => {
    return bifurcateBy(user.sessions, (session) => {
      // some legacy sessions have empty courses array
      const sessionCoursesModules = session.courses[0]?.modules
        ? session.courses[0]?.modules
        : [];
      const { total, complete } = calculateProgress({
        user,
        modules: [...session?.modules, ...sessionCoursesModules],
        session,
      });
      return complete === total;
    });
  }
);

export const selectInProgressCount = createSelector(
  selectBifurcatedSessions,
  ([, inProgressSessions]) => inProgressSessions?.length || 0
);

export const selectAchievements = createSelector(
  selectCurrentUser,
  (user) => user?.achievements || []
);

export const selectPartnerModules = createSelector(
  selectCurrentUser,
  (user) => user?.partner?.modules || []
);

export const selectRenewalExams = createSelector(
  selectCurrentUser,
  (user) => user?.modules || []
);

export const selectBifurcatedRenewalExams = createSelector(
  selectCurrentUser,
  selectRenewalExams,
  (user, renewalExams) => {
    return bifurcateBy(renewalExams, (renewalExam) => {
      const isQuizModule = renewalExam?.module_type === 'QuizModule';
      const progress = user?.progress?.[renewalExam.module_id._id];
      const isPassed = isQuizModule ? !!progress?.passed : false;
      const isCompleted = isQuizModule && isPassed;
      return isCompleted;
    });
  }
);

export const selectRenewalExamsCount = createSelector(
  selectProgress,
  selectRenewalExams,
  (progress, renewalExams) => {
    const badgeCount = renewalExams.reduce((count, { module_id: { _id } }) => {
      const moduleProgress = progress[_id];
      if (!moduleProgress?.passed) {
        count = count + 1;
      }
      return count;
    }, 0);

    return badgeCount;
  }
);

export const selectUnlockedRenewalExamsCount = createSelector(
  selectProgress,
  selectRenewalExams,
  (progress, renewalExams) => {
    const badgeCount = renewalExams.reduce(
      (count, { module_id: { _id, renewal_stripe_payment_id } }) => {
        const moduleProgress = progress[_id];
        if (!moduleProgress?.passed && renewal_stripe_payment_id) {
          count = count + 1;
        }
        return count;
      },
      0
    );

    return badgeCount;
  }
);

export const selectIsActiveTrainer = createSelector(
  selectAchievements,
  (achievements = []) =>
    achievements
      .filter(({ name }) => /trainer/i.test(name))
      .some(({ date_expires }) =>
        moment(date_expires).isSameOrAfter(moment(), 'day')
      )
);

export const selectIsAdmin = (state) =>
  state.auth.user?.role === 'admin' || false;

export const selectCompanyId = (state) =>
  state.auth.user?.companies?.length && state.auth.user?.companies[0].company;

export const selectTeamId = (state) =>
  state.auth.user?.companies?.length && state.auth.user?.companies[0].team;

export const selectCompanyRole = (state) =>
  state.auth.user?.companies?.length && state.auth.user?.companies[0].role;

export const selectHasRenewalExperiment = (state) =>
  state.auth.user.experiments.findIndex(
    ({ name }) => name === 'Renewal Cadence'
  ) >= 0;
