import React, { useEffect } from 'react';
import { Redirect, useHistory, Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Container,
  Fade,
  FormControlLabel,
  Grid,
  Hidden,
  InputAdornment,
  Link,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import PasswordIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/MailOutline';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';

import { useLoginMutation } from 'app/api';
import {
  setCredentials,
  setRememberedEmail,
  toggleRememberMe,
} from 'features/auth/slice';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      paddingTop: 'calc(100vh / 8)',
    },
  },
  loginForm: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
      padding: theme.spacing(10),
      paddingTop: theme.spacing(14),
    },
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
    },
    padding: theme.spacing(3),
    width: '100%',
    backgroundColor: '#eaeaea',
    paddingBottom: theme.spacing(7),
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  loginImageRoot: ({ isKoc }) => {
    if (isKoc) {
      return {
        width: '50%',
        height: '640px',
        padding: theme.spacing(3),
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        backgroundColor: '#fff',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundImage: 'url(login-koc.jpg)',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'right',
      };
    } else {
      return {
        width: '50%',
        height: '640px',
        padding: theme.spacing(3),
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        backgroundColor: theme.palette.secondary.main,
        backgroundSize: 'cover',
        backgroundRepeat: 'none',
        backgroundPosition: 'right bottom',
        backgroundImage: 'url(login.png)',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'right',
      };
    }
  },
  loginImageText: ({ isKoc }) => ({
    color: isKoc ? theme.palette.primary.main : '#fff',
    fontWeight: 300,
  }),
  loginImageTextStrong: ({ isKoc }) => ({
    color: isKoc ? theme.palette.primary.main : '#fff',
    fontWeight: 600,
    marginLeft: 'auto',
    paddingBottom: '.75rem',
    borderBottom: `1px solid ${isKoc ? theme.palette.primary.main : '#fff'}`,
    whiteSpace: 'nowrap',
  }),
  loginGridItem: {
    paddingBottom: theme.spacing(2),
  },
  helpLink: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
  },
  link: {
    fontSize: 15,
    fontFamily: theme.typography.fontFamily,
  },
  linkText: {},
}));

const schema = yup.object().shape({
  username: yup.string().email().required(),
  password: yup.string().required(),
});

const getRedirectPath = ({ appRole, companyRole, companyId, teamId }) => {
  switch (appRole) {
    // for students, prefer sending them to their company or team dash first before the student dash
    case 'student':
      if (companyRole === 'companyAdmin') {
        return `/companies/${companyId}`;
      } else if (companyRole === 'teamAdmin') {
        return `/companies/${companyId}/teams/${teamId}`;
      } else {
        return '/student';
      }
    // trainers and assistants both go to the trainer dashboard
    case 'trainer':
    case 'assistant':
      return '/trainer';
    case 'admin':
      return '/admin';
    default:
      return '/student';
  }
};

export default function Login() {
  const { t } = useTranslation('auth');
  const { location } = useHistory();
  const { user, rememberMe, rememberedEmail } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: rememberedEmail || '',
    },
  });
  const [login, { isLoading }] = useLoginMutation();
  const watchUsername = watch('username');
  const classes = useStyles({ isKoc: /koc/i.test(window.location.hostname) });

  useEffect(() => {
    if (rememberMe) {
      dispatch(setRememberedEmail(watchUsername));
    }
  }, [dispatch, rememberMe, watchUsername]);

  const onSubmit = async (data) => {
    try {
      // login returns a promise, we unwrap it to get the data in the response
      const payload = await login(data).unwrap();
      dispatch(setCredentials(payload));
      // we don't need to redirect to the dashboard because the state will get updated and the condition we have below will handle it
    } catch (err) {
      // toast notification or something so the user knows there was an error
      // for now we'll dispatch to the store and set the state.auth.error so that the user can see it below
      if (err?.status === 401) {
        setError('username', { type: 'manual', message: ' ' });
        setError('password', {
          type: 'manual',
          message: 'incorrect username or password',
        });
      } else {
        setError('username', { type: 'manual', message: err?.data?.message });
      }
    }
  };

  if (user) {
    const companyId = user?.companies?.length && user.companies[0].company;
    const teamId = user?.companies?.length && user.companies[0].team;
    const companyRole = user?.companies?.length && user.companies[0].role;

    const appRole = user?.role;

    const isPrivileged =
      ['admin', 'trainer', 'assistant'].includes(user?.role) ||
      ['companyAdmin', 'teamAdmin'].includes(companyRole);
    const cameFrom = location?.state?.from?.pathname;

    const redirectPath = getRedirectPath({
      appRole,
      companyRole,
      companyId,
      teamId,
    });

    // if a user came from some other route in the app, redirect them back after login
    if (cameFrom) {
      // if a privileged user came from /student, send them to /trainer
      // this handles the case where a user refreshes the page, it sends them to student dash, then to login, even though they aren't a student
      if (isPrivileged && cameFrom === '/student') {
        return <Redirect to={redirectPath} />;
      } else {
        return <Redirect to={{ pathname: location.state.from.pathname }} />;
      }
    } else {
      return <Redirect to={redirectPath} />;
    }
  }

  const aep = 'Agile Education';

  return (
    <Container className={classes.root} disableGutters>
      <Fade in timeout={1000}>
        <Grid container>
          <Hidden smDown>
            <Grid item className={classes.loginImageRoot}>
              <Trans i18nKey="auth:welcome">
                <Typography variant="h4" className={classes.loginImageText}>
                  Welcome to
                </Typography>
                <Typography variant="h4" className={classes.loginImageText}>
                  The {{ aep }}
                </Typography>
                <Typography
                  variant="h3"
                  className={classes.loginImageTextStrong}
                >
                  ScrumLab
                </Typography>
              </Trans>
            </Grid>
          </Hidden>
          <Grid container className={classes.loginForm}>
            <Grid container>
              <Grid item xs>
                <Typography variant="subtitle1">
                  {t('welcomeMessage')}
                </Typography>
              </Grid>
              <Grid item>
                <Link
                  component="a"
                  target="_blank"
                  href="mailto:online-learning@scruminc.com?subject=Issue accessing ScrumLab"
                  className={classes.helpLink}
                  paragraph
                >
                  {t('needHelp')}
                </Link>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    placeholder={t('email')}
                    label={t('email')}
                    InputProps={{
                      inputProps: {
                        'data-cy': 'email',
                      },
                      style: {
                        backgroundColor: '#fff',
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={errors?.username}
                    helperText={errors?.username?.message || ' '}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    placeholder={t('password')}
                    label={t('password')}
                    type="password"
                    InputProps={{
                      inputProps: {
                        'data-cy': 'password',
                      },
                      style: { backgroundColor: '#fff' },
                      startAdornment: (
                        <InputAdornment position="start">
                          <PasswordIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={errors?.password}
                    helperText={errors?.password?.message || ' '}
                  />
                )}
              />
              <Grid container>
                <Grid container xs={12} className={classes.loginGridItem}>
                  <Grid item xs>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="rememberMe-checkbox"
                          checked={rememberMe}
                          value={String(rememberMe)}
                          onChange={(e) =>
                            dispatch(toggleRememberMe(e.target.checked))
                          }
                          color="secondary"
                          inputProps={{
                            'data-cy': 'rememberMe-checkbox',
                          }}
                        />
                      }
                      label={t('rememberMe')}
                    />
                  </Grid>
                  <Grid item alignItems="flex-end">
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={isLoading}
                      size="large"
                    >
                      {t('login')}
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  justifyContent="space-between"
                  className={classes.loginGridItem}
                >
                  <Grid item xs spacing={2}>
                    <Link
                      id="self_signup_link"
                      component={RouterLink}
                      to="/self-signup"
                      paragraph
                      className={classes.link}
                      color="secondary"
                      data-cy="self-signup-link"
                    >
                      <Trans i18nKey="auth:register">
                        Need an account?{' '}
                        <span
                          className={classes.linkText}
                          data-cy="self-signup-link"
                        >
                          Register here.
                        </span>
                      </Trans>
                    </Link>
                  </Grid>
                  <Grid item spacing={2} alignItems="flex-end">
                    <Link
                      component={RouterLink}
                      to="/reset"
                      className={classes.link}
                      paragraph
                      color="secondary"
                    >
                      {t('forgotPassword')}
                    </Link>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs
                  className={classes.loginGridItem}
                  style={{ paddingTop: '5rem' }}
                >
                  <Typography className={classes.link}>
                    <Trans i18nKey="auth:agreement">
                      By signing in to ScrumLab, you agree to our{' '}
                      <Link
                        href="https://www.scruminc.com/terms-of-use/"
                        className={classes.linkText}
                        target="_blank"
                        component="a"
                        color="secondary"
                      >
                        Terms of Service
                      </Link>{' '}
                      and{' '}
                      <Link
                        href="https://www.scruminc.com/privacy-policy/"
                        className={classes.linkText}
                        target="_blank"
                        color="secondary"
                        component="a"
                      >
                        Privacy Policy
                      </Link>
                      .
                    </Trans>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Fade>
    </Container>
  );
}
