import moment from 'moment';

const calculateProgress = ({ user, modules, session }) => {
  const { totalRequired, complete } = modules.reduce(
    (acc, m) => {
      if (m.required) {
        acc.totalRequired += 1;
        if (user?.progress[m.module_id._id]?.complete === true) {
          acc.complete += 1;
        }
      }
      return acc;
    },
    { totalRequired: 0, complete: 0 }
  );

  // Create new session variable to update for non required modules.
  let total = totalRequired;

  const hasTypeFormModule = modules.some(
    (module) => module.module_type === 'TypeformModule'
  );
  const noRequiredModules = modules.some((module) => module.required === false);
  const noQuizModules =
    modules.filter((module) => module.module_type === 'QuizModule').length ===
    0;

  if (!hasTypeFormModule && noRequiredModules && noQuizModules) {
    const isBeforeToday = moment(session?.date_end).isBefore(moment(), 'day');
    const isLegacy = session?.name?.includes('Legacy');
    const hasRatings =
      user?.ratings?.filter((rating) => rating.session === session?._id)
        ?.length > 0;
    if (isBeforeToday && !isLegacy && !hasRatings) {
      // increment the total so that the class remains "in progress"
      total += 1;
    }
  }

  const percentage = total === 0 ? 0 : (complete / total).toFixed(2) * 100;
  return { total, complete, percentage };
};

export default calculateProgress;
