import providesTagsHelper from './helpers/providesTags';

export default function groups(builder) {
  return {
    getGroups: builder.query({
      query: (partnerId) => `/groups?partner=${partnerId}`,
      providesTags: (result) => providesTagsHelper({ type: 'Group', result }),
    }),
    createGroup: builder.mutation({
      query: (group) => ({
        url: '/groups',
        method: 'POST',
        body: group,
      }),
      invalidatesTags: ['Group'],
    }),
  };
}
