export default function renewalEndpoints(builder) {
  return {
    getRenewal: builder.query({
      query: (name) => `/renewals/${name}`,
    }),
    purchaseRenewal: builder.mutation({
      query: ({ paymentIntentId, achievementId, amount, renewingYears }) => ({
        url: `/renewals/purchase_renewal`,
        method: 'POST',
        body: {
          paymentIntentId,
          achievementId,
          amount,
          renewingYears,
        },
      }),
      invalidatesTags: (result) => (result ? ['AuthUser', 'Achievement'] : []),
    }),
    createPaymentIntent: builder.mutation({
      query: ({ achievementId, cadence }) => ({
        url: '/renewals/create-payment-intent',
        method: 'POST',
        body: {
          achievementId,
          cadence,
        },
      }),
    }),
  };
}
