import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  spacing: 8,
  palette: {
    primary: {
      light: '#5b5b5b',
      main: '#333333',
      dark: '#232323',
    },
    secondary: {
      light: '#d63333',
      main: '#cc0000',
      dark: '#a60321',
      mainYellow: '#ffeb49',
      lightYellow: '#fff080',
      greenCheck: '#0BA81A',
      lightBlue: '#80bdff',
      darkPurple: '#7e35cc',
      lightPurple: '#cfcef2',
      mediumGray: '#666666',
      lightGray: '#f4f4f4',
      gray: '#999999',
      pink: '#ffdddd',
      gainsboro: '#dcdcdc',
    },
    gray: {
      light: '#f4f4f4',
      main: '#999',
      dark: '#666',
    },
    purple: {
      main: '#7e35CC',
      dark: '#46207F',
    },
    info: {
      main: '#80bdff',
    },
    success: {
      main: '#0BA81A',
    },
    error: {
      main: '#CC0000',
    },
    button: {
      white: '#fff',
      whiteHover: '#999999',
      yellow: '#ffeb49',
      yellowHover: '#7F7524',
      green: '#4caf50',
      greenHover: '#357a38',
      orangeRed: '#f44336',
      red: '#cc0000',
      redHover: '#8e0000',
      purple: '#7e35cc',
      purpleHover: '#46207f',
      gray: '#999999',
      grayHover: '#666666',
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: `"Open Sans", "Helvetica", "Arial", "Lucida", sans-serif`,
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    body2: {
      fontSize: 14,
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        background: 'linear-gradient(180deg, #333333 30%, #232323 90%)',
        color: '#fff',
        '&:hover': {
          background: '#232323',
          color: '#fff',
        },
        '&$disabled': {
          color: '#fff',
          opacity: 0.5,
        },
      },
      containedSecondary: {
        background: 'linear-gradient(180deg, #cc0000 30%, #a60321 90%)',
        color: '#fff',
        '&:hover': {
          background: '#a60321',
          color: '#fff',
        },
        '&$disabled': {
          color: '#fff',
          opacity: 0.5,
        },
      },
    },
    MuiPaper: {
      root: {},
      elevation1: {
        boxShadow: '5px 5px 15px #c8c8c8, -5px -5px 15px #ffffff',
      },
    },
    MuiAlert: {
      root: {
        boxShadow: '5px 5px 15px #c8c8c8, -5px -5px 15px #ffffff',
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
