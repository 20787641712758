import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  linkedIn: {
    userId: null,
    accessToken: null,
    expiration: null,
  },
  share: {
    isOpen: false,
    hasConfirmed: false,
    toFeed: true,
    toProfile: true,
    message: '',
    achievement: null,
    popupBlocked: false,
  },
  renew: {
    achievement: null,
    isCheckingOut: false,
    clientSecret: null,
    option: '5-year',
    optionDetail: null,
  },
};

export const achievementsSlice = createSlice({
  name: 'achievements',
  initialState,
  reducers: {
    setLinkedInData: (
      state,
      {
        payload: {
          userId = null,
          access_token: accessToken = null,
          expires_in: expiresIn = null,
        } = {},
      }
    ) => {
      state.linkedIn.userId = userId;
      state.linkedIn.accessToken = accessToken;
      state.linkedIn.expiration = moment()
        .add(expiresIn, 'seconds')
        .toISOString();
    },
    setRenew: (state, { payload: achievement = null }) => {
      state.renew.achievement = achievement;
      if (!achievement) {
        state.renew.isCheckingOut = false;
        state.renew.optionDetail = null;
      }
    },
    startCheckout: (state, { payload: isCheckingOut = false }) => {
      state.renew.isCheckingOut = isCheckingOut;
      if (!isCheckingOut) {
        state.renew.optionDetail = null;
      }
    },
    setClientSecret: (state, { payload = null }) => {
      state.renew.clientSecret = payload;
    },
    setRenewalOption: (
      state,
      { payload: { cadence = '5-year', detail = null } = {} }
    ) => {
      state.renew.option = cadence;
      if (detail) {
        state.renew.optionDetail = detail;
      }
    },
    setShare: (
      state,
      {
        payload: {
          isOpen = false,
          hasConfirmed = false,
          toFeed = true,
          toProfile = true,
          achievement = null,
          message = initialState.share.message,
        } = {},
      }
    ) => {
      state.share.isOpen = isOpen;
      state.share.hasConfirmed = hasConfirmed;
      state.share.toFeed = toFeed;
      state.share.toProfile = toProfile;
      state.share.achievement = achievement;
      state.share.message = message;
    },
    setShareAchievement: (state, { payload: achievement = null }) => {
      state.share.achievement = achievement;
    },
    setShareMessage: (
      state,
      { payload: message = initialState.share.message }
    ) => {
      state.share.message = message;
    },
    setShareHasConfirmed: (state, { payload: hasConfirmed = false }) => {
      state.share.hasConfirmed = hasConfirmed;
    },
    setShareToFeed: (state, { payload: toFeed = true }) => {
      state.share.toFeed = toFeed;
    },
    setShareToProfile: (state, { payload: toProfile = true }) => {
      state.share.toProfile = toProfile;
    },
    setPopupBlocked: (state, { payload: popupBlocked }) => {
      state.share.popupBlocked = popupBlocked;
    },
  },
});

export const {
  setLinkedInData,
  setRenew,
  startCheckout,
  setClientSecret,
  setRenewalOption,
  setShare,
  setShareAchievement,
  setShareMessage,
  setShareHasConfirmed,
  setShareToFeed,
  setShareToProfile,
  setPopupBlocked,
} = achievementsSlice.actions;

export const actions = achievementsSlice.actions;
export default achievementsSlice.reducer;

export const selectShare = (state) => state.achievements.share;
export const selectShareableAchievement = (state) =>
  state.achievements.share.achievement;
export const selectRenewableAchievement = (state) =>
  state.achievements.renew.achievement;
export const selectLinkedInUserId = (state) =>
  state.achievements.linkedIn.userId;
export const selectLinkedInAccessToken = (state) =>
  moment().isBefore(state.achievements.linkedIn.expiration)
    ? state.achievements.linkedIn.accessToken
    : null;
export const selectIsCheckingOut = (state) =>
  state.achievements.renew.isCheckingOut;
export const selectClientSecret = (state) =>
  state.achievements.renew.clientSecret;
export const selectRenewalOption = (state) => state.achievements.renew.option;
export const selectRenewalOptionDetail = (state) =>
  state.achievements.renew.optionDetail;
export const selectPopupBlocked = (state) =>
  state.achievements.share.popupBlocked;
