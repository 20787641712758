import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: '',
  order: 'asc',
  orderBy: 'full_name',
  page: 0,
  limit: 100,
  courseFilter: 'all',
  selectedStudents: [],
  addAdminDialogOpen: false,
  courseAdminDialogOpen: false,
  confirmRemoveAdminDialog: {
    open: false,
    name: '',
    userId: null,
  },
  confirmInviteCodeDialog: {
    open: false,
    inviteCodeId: null,
    teamId: null,
    companyId: null,
  },
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    reset: (state) => {
      for (const key in initialState) {
        if ({}.hasOwnProperty.call(initialState, key)) {
          state[key] = initialState[key];
        }
      }
    },
    setSearch: (state, { payload: search }) => {
      if (typeof search === 'string') {
        state.search = search;
      } else {
        state.search = '';
      }
      state.page = 0;
    },
    setOrder: (state, { payload: order }) => {
      state.order = order;
    },
    setOrderBy: (state, { payload: orderBy }) => {
      state.orderBy = orderBy;
    },
    setPage: (state, { payload: page }) => {
      state.page = page;
    },
    setLimit: (state, { payload: limit = 100 }) => {
      state.limit = limit;
    },
    setCourseFilter: (state, { payload: courseFilter = 'all' }) => {
      state.courseFilter = courseFilter;
    },
    setSelectedStudents: (state, { payload: userId }) => {
      if (userId === 'clear') {
        state.selectedStudents = [];
      } else {
        const selectedIndex = state.selectedStudents.findIndex(
          (id) => id === userId
        );
        if (selectedIndex === -1) {
          state.selectedStudents.push(userId);
        } else if (selectedIndex >= 0) {
          state.selectedStudents.splice(selectedIndex, 1);
        }
      }
    },
    setAddAdminDialogOpen: (state, { payload: open = false }) => {
      state.addAdminDialogOpen = open;
    },
    setCourseAdminDialogOpen: (state, { payload: open = false }) => {
      state.courseAdminDialogOpen = open;
    },
    toggleConfirmInviteCodeDialog: (state, { payload = false }) => {
      state.confirmInviteCodeDialog.open = payload;
      // reset the inviteCodeId when the dialog closes
      if (!payload) {
        state.confirmInviteCodeDialog.inviteCodeId = null;
        state.confirmInviteCodeDialog.teamId = null;
        state.confirmInviteCodeDialog.companyId = null;
      }
    },
    setInviteCodeId: (
      state,
      { payload: { inviteCodeId = null, teamId = null, companyId = null } = {} }
    ) => {
      state.confirmInviteCodeDialog.inviteCodeId = inviteCodeId;
      state.confirmInviteCodeDialog.teamId = teamId;
      state.confirmInviteCodeDialog.companyId = companyId;
      // opens the dialog if the inviteCodeId is being set
      if (inviteCodeId) {
        state.confirmInviteCodeDialog.open = true;
      }
    },
  },
});

export default teamsSlice.reducer;

export const actions = teamsSlice.actions;
export const {
  reset,
  setSearch,
  setOrder,
  setOrderBy,
  setPage,
  setLimit,
  setCourseFilter,
  setSelectedStudents,
  setAddAdminDialogOpen,
  setCourseAdminDialogOpen,
  toggleConfirmInviteCodeDialog,
  setInviteCodeId,
} = actions;

export const selectSlice = (state) => state.teams;
export const selectCourseFilter = (state) => selectSlice(state).courseFilter;
export const selectSelectedStudents = (state) =>
  selectSlice(state).selectedStudents;
export const selectAddAdminDialogOpen = (state) =>
  selectSlice(state).addAdminDialogOpen;
export const selectCourseAdminDialogOpen = (state) =>
  selectSlice(state).courseAdminDialogOpen;
export const selectConfirmInviteCodeDialog = (state) =>
  state.teams.confirmInviteCodeDialog;
