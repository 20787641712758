import { api } from './';
import providesTagsHelper from './helpers/providesTags';
import { setUserPhoto, toggleOptIn } from 'features/auth/slice';

// this helper function is used to invalidate the cache when the user changes
const invalidateUser = (result, error, arg) =>
  arg?.userId ? [{ type: 'User', id: arg.userId }] : ['User'];

export default function getUsersEndpoints(build) {
  return {
    listUserCourses: build.query({
      query: (userId) => `/users/${userId}/courses`,
      providesTags: (result) =>
        providesTagsHelper({ type: 'UserCourse', result }),
    }),
    registerCourse: build.mutation({
      async queryFn(code, { dispatch, getState }, _extraOptions, fetchWithBQ) {
        const userId = getState().auth.user._id;

        const response = await fetchWithBQ({
          url: `/users/${userId}/courses`,
          method: 'POST',
          body: { invite_code: code },
        });

        if (response.data) {
          // for on-demand course registrations, refresh the auth user to pick up the course
          if (!response.data?.sessions) {
            dispatch(api.util.invalidateTags(['UserCourse']));
          }
          return { data: response.data };
        } else {
          return { error: response?.error || 'Something went wrong' };
        }
      },
    }),
    listUsers: build.query({
      query: (query) => {
        const queryString = new URLSearchParams(query).toString();
        return `/users?${queryString}`;
      },
      transformResponse: (response) => {
        return response?.docs
          ? {
              users: response.docs,
              totalUsers: response.totalDocs,
            }
          : response;
      },
      providesTags: ({ users }) =>
        providesTagsHelper({ type: 'User', result: users }),
    }),
    getUser: build.query({
      query: (id) => `/users/${id}`,
      providesTags: (result) =>
        result ? [{ type: 'User', id: result._id }] : [],
    }),
    getUserByUsername: build.query({
      query: (username) => {
        const params = new URLSearchParams({ username });
        return `/users/username?${params.toString()}`;
      },
      providesTags: (result) => providesTagsHelper({ type: 'User', result }),
    }),
    getUserProgress: build.query({
      async queryFn(_args, { getState }, _extra, fetchWithBQ) {
        const userId = getState().auth.user._id;
        const res = await fetchWithBQ(`/users/${userId}/progress`);
        return res.data ? { data: res.data } : { error: res.error };
      },
      providesTags: ['UserProgress'],
    }),
    signUp: build.mutation({
      query: (user) => ({
        url: '/users/self-signup',
        method: 'POST',
        body: user,
      }),
    }),
    updateUser: build.mutation({
      query: (user) => ({
        url: `/users/${user._id}`,
        method: 'PUT',
        body: user,
      }),
      invalidatesTags: (result, error, arg) =>
        arg?._id ? [{ type: 'User', id: arg._id }] : ['User'],
    }),
    updateUserField: build.mutation({
      async queryFn(field, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        const userId = getState().auth.user._id;
        const res = await fetchWithBQ({
          url: `/users/update_user/${field}/${userId}`,
          method: 'POST',
        });

        if (res.data) {
          dispatch(toggleOptIn(field));
          return { data: res.data };
        } else {
          return { error: res.error };
        }
      },
    }),
    updateUserPhoto: build.mutation({
      async queryFn(file, { getState, dispatch }, _extraOptions, fetchWithBQ) {
        // generate the public id, timestamp, and signature
        const response = await fetchWithBQ({
          url: '/users/upload/image',
          method: 'POST',
          body: { data: file.path },
        });

        // set up the form data and upload file to cloudinary
        const formData = new FormData();
        formData.append('file', file);
        formData.append('public_id', response.data.public_id);
        formData.append('api_key', '792777696515396');
        formData.append('timestamp', response.data.timestamp);
        formData.append('signature', response.data.signature);

        const startUpload = await fetch(
          'https://api.cloudinary.com/v1_1/dmwoxjusp/image/upload',
          {
            method: 'POST',
            body: formData,
          }
        );
        const startUploadData = await startUpload.json();

        // update the user's photo in the db with the new url
        const updateUser = await fetchWithBQ({
          url: `/users/update_user/image`,
          method: 'POST',
          body: {
            url: startUploadData.secure_url,
            user: getState().auth.user._id,
          },
        });

        if (updateUser.data) {
          dispatch(setUserPhoto(updateUser.data.url));
          return { data: updateUser.data };
        } else {
          return { error: updateUser.error };
        }
      },
      invalidatesTags: ['AuthUser'],
    }),
    listAchievements: build.query({
      async queryFn(_args, { getState }, _extraOptions, fetchWithBQ) {
        const { _id } = getState().auth.user;
        const res = await fetchWithBQ({
          url: `/users/achievements/${_id}`,
          method: 'GET',
        });

        if (res.data) {
          return { data: res.data };
        } else {
          return { error: res.error };
        }
      },
      providesTags: ['Achievement'],
    }),
    addAchievement: build.mutation({
      query: ({ userId, achievement }) => ({
        url: `/users/${userId}/achievements`,
        method: 'POST',
        body: achievement,
      }),
      invalidatesTags: invalidateUser,
    }),
    regenerateAchievement: build.mutation({
      query: ({ userId, achievementId }) => ({
        url: `/users/${userId}/achievements/${achievementId}/regenerate`,
        method: 'PUT',
      }),
      invalidatesTags: invalidateUser,
    }),
    updateAchievement: build.mutation({
      query: ({ userId, achievement }) => ({
        url: `/users/${userId}/achievements/${achievement._id}`,
        method: 'PUT',
        body: achievement,
      }),
      invalidatesTags: invalidateUser,
    }),
    deleteAchievement: build.mutation({
      query: ({ userId, achievementId }) => ({
        url: `/users/${userId}/achievements/${achievementId}`,
        method: 'DELETE',
      }),
      invalidatesTags: invalidateUser,
    }),
    shareAchievement: build.mutation({
      async queryFn(
        { achievementName, achievementUrl, message, language = 'en' },
        { getState },
        _,
        fetchWithBQ
      ) {
        const { userId, accessToken } = getState().achievements.linkedIn;
        const res = await fetchWithBQ({
          url: '/linkedIn/shares',
          method: 'POST',
          body: {
            achievementName,
            achievementUrl,
            message,
            userId,
            accessToken,
            language,
          },
        });

        if (res.data) {
          return { data: res.data };
        } else {
          return { error: res.error };
        }
      },
    }),
    lockRenewalExam: build.mutation({
      query: ({ userId, achievementId, moduleId, addUnlockedRenewalExam }) => ({
        url: `/users/${userId}/achievements/${achievementId}/${moduleId}/lockRenewalExam`,
        method: 'POST',
        body: { addUnlockedRenewalExam },
      }),
      invalidatesTags: invalidateUser,
    }),
    unlockRenewalExam: build.mutation({
      query: ({ userId, achievementId, moduleId, addUnlockedRenewalExam }) => ({
        url: `/users/${userId}/achievements/${achievementId}/${moduleId}/unlockRenewalExam`,
        method: 'POST',
        body: { addUnlockedRenewalExam },
      }),
      invalidatesTags: invalidateUser,
    }),
    listTrainers: build.query({
      query: (partnerId) => `/users/dropdowntrainers?partner=${partnerId}`,
      providesTags: (result) => providesTagsHelper({ type: 'Trainer', result }),
    }),
    addTrainer: build.mutation({
      query: (trainerData) => ({
        url: '/users/trainers',
        method: 'POST',
        body: trainerData,
      }),
    }),
    endSabbatical: build.mutation({
      query: () => ({
        url: '/users/trainer/end-sabbatical',
        method: 'PUT',
      }),
      invalidatesTags: ['AuthUser', { type: 'Report', id: 'renewal' }],
    }),
    addUserToSession: build.mutation({
      query: (body) => ({
        url: '/users/addUserToSession',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        result ? [{ type: 'Session', id: arg.sessionId }] : [],
    }),
    listUserCompanies: build.query({
      query: (userId) => `/users/${userId}/companies`,
    }),
    submitSurveyResponse: build.mutation({
      query: ({ userId, surveyResponse }) => ({
        url: `/users/${userId}/surveyResponse`,
        method: 'POST',
        body: surveyResponse,
      }),
      invalidatesTags: ['UserProgress'],
    }),
  };
}
