import providesTagsHelper from './helpers/providesTags';

export default function getTeamsEndpoints(builder) {
  return {
    getTeam: builder.query({
      query: ({ teamId, companyId }) =>
        `/companies/${companyId}/teams/${teamId}`,
      providesTags: (result) => providesTagsHelper({ type: 'Team', result }),
    }),
    listTeams: builder.query({
      query: (companyId) => `/companies/${companyId}/teams`,
      providesTags: (result) => providesTagsHelper({ type: 'Team', result }),
    }),
    getTeamExport: builder.query({
      query: ({ teamId, companyId }) => ({
        url: `/companies/${companyId}/teams/${teamId}/report?type=export`,
        responseHandler: (response) => response.text(),
      }),
    }),
    assignTeamCourse: builder.query({
      query: ({ teamId, companyId, courseId }) => ({
        url: `/teams/${teamId}/${companyId}`,
        method: 'POST',
        body: { courseId },
        responseHandler: (response) => response.text(),
      }),
    }),
    listTeamUsers: builder.query({
      query: ({ teamId, companyId, ...query }) => {
        const queryString = new URLSearchParams({ ...query }).toString();
        return `/companies/${companyId}/teams/${teamId}/users?${queryString}`;
      },
      providesTags: (result) =>
        providesTagsHelper({ type: 'CompanyUser', result: result?.docs || [] }),
    }),
    assignUsersCourse: builder.mutation({
      query: ({ companyId, teamId, courseId, userIds }) => ({
        url: `/companies/${companyId}/teams/${teamId}/courses/${courseId}/users`,
        method: 'POST',
        body: {
          userIds,
        },
      }),
      invalidatesTags: ['CompanyUser', 'Company', 'Team'],
    }),
    unassignUsersCourse: builder.mutation({
      query: ({ companyId, teamId, courseId, userIds }) => ({
        url: `/companies/${companyId}/teams/${teamId}/courses/${courseId}/users`,
        method: 'DELETE',
        body: {
          userIds,
        },
      }),
      invalidatesTags: ['CompanyUser', 'Company', 'Team'],
    }),
    listInviteCodes: builder.query({
      query: ({ companyId, teamId }) =>
        `/companies/${companyId}/teams/${teamId}/invites`,
      providesTags: (result) =>
        providesTagsHelper({ type: 'InviteCode', result }),
    }),
    updateInviteCode: builder.mutation({
      query: ({ companyId, teamId, inviteCodeId, ...body }) => ({
        method: 'PUT',
        url: `/companies/${companyId}/teams/${teamId}/invites/${inviteCodeId}`,
        body,
      }),
      invalidatesTags: ['InviteCode'],
    }),
    createInviteCode: builder.mutation({
      query: ({ companyId, teamId, ...body }) => ({
        method: 'POST',
        url: `/companies/${companyId}/teams/${teamId}/invites`,
        body,
      }),
      invalidatesTags: ['InviteCode'],
    }),
  };
}
