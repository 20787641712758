import providesTags from './helpers/providesTags';

export default function videoModules(builder) {
  return {
    getVideoModule: builder.query({
      query: (id) => ({
        url: `/VideoModule/${id}`,
      }),
      providesTags: (result) => providesTags({ type: 'VideoModule', result }),
    }),
    updateVideoModule: builder.mutation({
      query: (videoModule) => ({
        url: `/VideoModule/${videoModule._id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['VideoModule'],
    }),
    addFilesToVideoModule: builder.mutation({
      query: (videoModule) => ({
        url: `/VideoModule/addFiles/${videoModule}`,
      }),
      invalidatesTags: ['VideoModule'],
    }),
    updateVideoProgress: builder.mutation({
      query: ({ videoModule, session, progress }) => {
        const { total, start } = progress;
        const percent = (start / total).toFixed(2) * 100;
        if (percent > 90) {
          progress.complete = true;
        }

        return {
          url: `/VideoModule/update_progress/${videoModule}/${session}`,
          method: 'POST',
          body: progress,
        };
      },
    }),
  };
}
