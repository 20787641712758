import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import * as Sentry from '@sentry/react';

import { api } from './api';

import reducers from 'reducers/rootReducer';
import achievements from 'features/achievements/slice';
import auth from 'features/auth/slice';
import courses from 'features/courses/slice';
import partners from 'features/partners/slice';
import student from 'features/student/slice';
import sessions from 'features/sessions/slice';
import users from 'features/users/slice';
import credentialLookup from 'features/credentialLookup/slice';
import poker, { pokerActions } from 'features/poker/slice';
import trainers from 'features/trainers/slice';
import feedback from 'features/feedback/slice';
import companies from 'features/companies/slice';
import teams from 'features/teams/slice';
import examAnalysis from 'features/examAnalysis/slice';
import surveyResponses from 'features/surveyResponses/slice';
import { saveState, setWithExpiry } from 'utils/localStorage';
import pokerMiddleware from './middleware/poker';

// const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const combinedReducer = combineReducers({
  // these are the OLD reducers that are still being used/brought in
  ...reducers,
  // these are the new reducers created using RTK
  achievements,
  auth,
  courses,
  partners,
  student,
  sessions,
  users,
  credentialLookup,
  poker,
  trainers,
  feedback,
  companies,
  teams,
  examAnalysis,
  surveyResponses,
  // RTK query api reducer
  [api.reducerPath]: api.reducer,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    // this case falls through so that it applies to either start/stop impersonating
    case 'auth/startImpersonating':
    case 'auth/stopImpersonating':
      // we want to preserve the auth slice of the state, but allow everything else to be wiped out
      // so that the rest of the data is re-initialized
      state = {
        auth: {
          ...state.auth,
        },
      };
      break;
    default:
      break;
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  // todo: we can enable this when our redux store isn't so big, right now this causes an error due to the payload size
  // enhancers: [sentryReduxEnhancer],
  // redux-thunk is automatically added by redux toolkit
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([api.middleware, pokerMiddleware]),
});

// subscribe invokes this listener func on every dispatch to the store
store.subscribe(() => {
  const auth = store.getState().auth;
  const poker = store.getState().poker;

  // save the token to localStorage as it changes in redux
  saveState('token', auth.token);

  // updating the local state for the rememberMe feature
  saveState('rememberMe', auth.rememberMe);
  saveState('rememberedEmail', auth.rememberMe ? auth.rememberedEmail : null);

  // save the scrumToken as it changes in redux
  // it'll show in localStorage like something is there, even if it's null since it's a base64 encoded object with a null value
  // this is ok
  setWithExpiry('scrumToken', auth.scrumToken);

  // set the language in localStorage
  // exception for tw because of our mix of scrumlab-translations using 'tw' but i18next needing it to be 'zh-TW'
  // updates the locale in redux if it's not the same as  the user's preferred language
  const language =
    auth?.user?.language === 'tw' ? 'zh-TW' : auth?.user?.language;
  saveState('i18nextLng', language || 'en');

  const firstName = auth?.user?.first_name;
  if (!poker.userName || poker.userName === '') {
    firstName && store.dispatch(pokerActions.setUserName(firstName));
  } else {
    // tracking the planning poker local storage data
    poker.userId && saveState('pokerUserId', poker.userId);
    poker.userName && saveState('pokerUserName', poker.userName);
  }
});

export default store;
