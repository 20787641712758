const drawerWidth = window.self === window.top ? 240 : 0;
const iFrame = window.self !== window.top;

const styles = ({ palette, breakpoints, spacing }) => {
  const { secondary, common, button } = palette;

  const iFrameRoot = {
    backgroundColor: common.white,
    padding: 0,
    margin: 0,
    height: '100%',
    width: '100%',
    minHeight: 'inherit',
  };

  return {
    root: iFrame
      ? iFrameRoot
      : {
          backgroundColor: secondary.lightGray,
          paddingTop: spacing(2),
          minHeight: window.self === window.top ? '100vh' : 0,
          '&.showDrawer': {
            [breakpoints.up('md')]: {
              width: `calc(100% - ${drawerWidth}px)`,
              marginLeft: drawerWidth,
            },
          },
        },
    footer: {
      backgroundColor: '#222',
      height: '30px',
      padding: '2rem',
      [breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      [breakpoints.down('sm')]: {
        height: 'auto',
      },
    },
    goBackBtn: {
      minWidth: '117px',
      padding: '.45rem 1rem',
      color: common.white,
      backgroundColor: palette.purple.main,
      '&:hover': {
        backgroundColor: palette.purple.dark,
      },
    },
    authBannerContainer: {
      width: '100%',
      margin: '0 240px',
      padding: '1.5rem',
      backgroundColor: palette.purple.main,
      [breakpoints.down('sm')]: {
        margin: '0',
      },
    },
    authBannerDiv: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '1.5rem 0',
      [breakpoints.up('md')]: {
        width: '80%',
      },
    },
    bannerText: {
      color: common.white,
      paddingRight: '1rem',
      [breakpoints.up('md')]: {
        fontSize: '16px',
      },
    },
    authBannerButton: {
      minWidth: '117px',
      padding: '.45rem 1rem',
      color: common.black,
      backgroundColor: button.yellow,
      '&:hover': {
        backgroundColor: button.yellowHover,
      },
    },
    renewalExamBannerContainer: {
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      margin: '0 240px',
      backgroundColor: secondary.main,
      [breakpoints.down('sm')]: {
        margin: '0',
        padding: '0 6rem',
      },
      [breakpoints.down('xs')]: {
        margin: '0',
        padding: '0',
      },
    },
    renewalExamBannerDiv: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '5rem',
      [breakpoints.down('sm')]: {
        margin: '0 1rem',
      },
      [breakpoints.up('md')]: {
        width: '80%',
      },
    },
    renewalExamBannerButton: {
      minWidth: '117px',
      padding: '.45rem 1rem',
      color: common.black,
      backgroundColor: button.white,
      '&:hover': {
        backgroundColor: button.whiteHover,
      },
    },
    infoIcon: {
      marginRight: '.5rem',
      color: common.white,
    },
  };
};

export default styles;
