import { createSlice } from '@reduxjs/toolkit';

const initialState = { currentTab: 0 };

export const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    changeTabs: (state, { payload: { currentTab } }) => {
      state.currentTab = currentTab;
    },
  },
});

export const { changeTabs } = studentSlice.actions;

export default studentSlice.reducer;
