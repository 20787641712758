import providesTagsHelper from './helpers/providesTags';

export default function getCompaniesEndpoints(builder) {
  return {
    listCompanies: builder.query({
      query: () => '/companies',
      providesTags: (result) => providesTagsHelper({ type: 'Company', result }),
    }),
    getCompany: builder.query({
      query: (companyId) => `/companies/${companyId}`,
      providesTags: (result) => providesTagsHelper({ type: 'Company', result }),
    }),
    createCompany: builder.mutation({
      query: (body) => ({
        url: '/companies',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Company'],
    }),
    updateCompany: builder.mutation({
      query: ({ companyId, name }) => ({
        url: `/companies/${companyId}`,
        method: 'PUT',
        body: { name },
      }),
      invalidatesTags: ['Company'],
    }),
    updateCompanyCourse: builder.mutation({
      query: ({ companyId, courseId, totalSeats }) => ({
        url: `/companies/${companyId}/courses/${courseId}`,
        method: 'PUT',
        body: { totalSeats },
      }),
      invalidatesTags: ['Company'],
    }),
    deleteCompany: builder.mutation({
      query: (companyId) => ({
        url: `/companies/${companyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Company'],
    }),
    getCompanyTeams: builder.query({
      query: (companyId) => `/companies/${companyId}/teams`,
      providesTags: (result) =>
        providesTagsHelper({ type: 'CompanyTeam', result }),
    }),
    listCompanyUsers: builder.query({
      query: ({ companyId, ...query }) => {
        const queryString = new URLSearchParams(query).toString();
        return `/companies/${companyId}/users?${queryString}`;
      },
      providesTags: (result) =>
        providesTagsHelper({ type: 'CompanyUser', result: result?.docs || [] }),
    }),
    getCompanyReport: builder.query({
      // optionally takes a courseId and either gets the full report or by course if it's provided
      query: ({ companyId, courseId }) => ({
        url: `/companies/${companyId}/courses/${
          courseId && courseId !== 'all' ? `${courseId}/` : ''
        }report?type=export`,
        responseHandler: (response) => response.text(),
      }),
    }),
    getExamAnalyis: builder.query({
      query: ({ companyId, teamId, courseId }) => {
        let url = `/companies/${companyId}`;
        if (teamId) url = `${url}/teams/${teamId}`;
        url = courseId ? `${url}/courses/${courseId}` : `${url}/courses`;
        return `${url}/report?type=exam`;
      },
    }),
    addCompanyTeam: builder.mutation({
      query: ({ companyId, name }) => ({
        url: `/companies/${companyId}/teams`,
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: ['CompanyTeam'],
    }),
    addTeamCourse: builder.mutation({
      query: ({ companyId, teamId, courseId, totalSeats }) => ({
        url: `/companies/${companyId}/teams/${teamId}/courses`,
        method: 'POST',
        body: { courseId, totalSeats },
      }),
      invalidatesTags: ['CompanyTeam'],
    }),
    deleteCompanyTeam: builder.mutation({
      query: ({ teamId, companyId }) => ({
        url: `/companies/${companyId}/teams/${teamId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CompanyTeam'],
    }),
    updateTeamCourseSeats: builder.mutation({
      query: ({ companyId, teamId, courseId, seatAdjustment, operation }) => ({
        url: `/companies/${companyId}/teams/${teamId}/courses/${courseId}`,
        method: 'PUT',
        body: { seatAdjustment, operation },
      }),
      invalidatesTags: ['CompanyTeam'],
    }),
    addCompanyUser: builder.mutation({
      query: ({ companyId, userId, teamId, role, user }) => ({
        url: `/companies/${companyId}/users`,
        method: 'POST',
        body: {
          userId,
          role,
          // teamId can be optional (for company admins)
          ...(teamId && {
            teamId,
          }),
          // a user with username, firstName, and lastName are required if the user doesn't exist
          ...(user && {
            user,
          }),
        },
      }),
      invalidatesTags: ['Company', 'CompanyUser'],
    }),
    removeCompanyUser: builder.mutation({
      query: ({ companyId, userId }) => ({
        url: `/companies/${companyId}/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Company', 'CompanyUser'],
    }),
    getCompanyUser: builder.query({
      query: ({ companyId, userId }) =>
        `/companies/${companyId}/users/${userId}`,
    }),
    updateCompanyUser: builder.mutation({
      query: ({ companyId, userId, teamId, role }) => ({
        url: `/companies/${companyId}/users/${userId}`,
        method: 'PUT',
        body: {
          role,
          teamId,
        },
      }),
      invalidatesTags: ['Company', 'CompanyUser', 'CompanyTeam'],
    }),
    transferUsersTeam: builder.mutation({
      query: ({ users, companyId, teamId }) => ({
        url: `/companies/${companyId}/teams/${teamId}/transfer`,
        responseHandler: (response) => response.text(),
        method: 'PUT',
        body: {
          users,
        },
      }),
      invalidatesTags: ['CompanyUser', 'CompanyTeam', 'Company'],
    }),
    addCompanyCourse: builder.mutation({
      query: ({ companyId, courseId, totalSeats = 0 }) => ({
        url: `/companies/${companyId}/courses`,
        method: 'POST',
        body: {
          courseId,
          totalSeats,
        },
      }),
      invalidatesTags: ['Company', 'CompanyUser'],
    }),
    getCoursesList: builder.query({
      query: ({ type }) => ({
        url: `/courses?type=${type}`,
        method: 'GET',
      }),
      providesTags: (result) => providesTagsHelper({ type: 'Course', result }),
    }),
    listSurveyResponses: builder.query({
      query: ({
        companyId,
        teamId,
        courseId,
        dateStart,
        dateEnd,
        limit,
        page,
      }) => {
        const searchParams = new URLSearchParams({
          ...(courseId && { courseId }),
          ...(dateStart && { dateStart }),
          ...(dateEnd && { dateEnd }),
          ...(limit && { limit }),
          // offset page by one to account for 0 index
          ...(page && { page: page - 1 }),
        }).toString();

        let url = `/companies/${companyId}`;
        if (teamId) url = `${url}/teams/${teamId}`;
        url = `${url}/surveyResponses?${searchParams}`;

        return { url };
      },
      providesTags: (result) =>
        providesTagsHelper({ type: 'SurveyResponse', result }),
    }),
  };
}
