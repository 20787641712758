import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: '',
  order: 'asc',
  orderBy: 'full_name',
  page: 0,
  limit: 100,
  addTeamDialogOpen: false,
  addCourseDialogOpen: false,
  deleteTeamDialogOpen: {
    open: false,
    teamId: null,
  },
  editCourseSeatsDialogOpen: {
    open: false,
    courseId: null,
    teamId: null,
  },
  addAdminDialogOpen: false,
  addTeamCourseDialogOpen: {
    open: false,
    teamId: null,
  },
  confirmRemoveAdminDialog: {
    open: false,
    name: '',
    userId: null,
  },
  courseFilter: 'all',
  transferStudents: {
    open: false,
    selectedStudents: [],
    selectedTeam: null,
  },
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setSearch: (state, { payload: search }) => {
      if (typeof search === 'string') {
        state.search = search;
      } else {
        state.search = '';
      }
      state.page = 0;
    },
    setOrder: (state, { payload: order }) => {
      state.order = order;
    },
    setOrderBy: (state, { payload: orderBy }) => {
      state.orderBy = orderBy;
    },
    setPage: (state, { payload: page }) => {
      state.page = page;
    },
    setLimit: (state, { payload: limit = 100 }) => {
      state.limit = limit;
    },
    setAddTeamDialogOpen: (state, { payload: teamDialogOpen }) => {
      state.addTeamDialogOpen = teamDialogOpen;
    },
    setAddCourseDialogOpen: (state, { payload: courseDialogOpen }) => {
      state.addCourseDialogOpen = courseDialogOpen;
    },
    setAddTeamCourseDialogOpen: (
      state,
      {
        payload: addTeamCourseDialogOpen = {
          open: false,
          teamId: null,
        },
      }
    ) => {
      state.addTeamCourseDialogOpen = addTeamCourseDialogOpen;
    },
    setDeleteTeamDialogOpen: (
      state,
      {
        payload: deleteTeamDialogOpen = {
          open: false,
          teamId: null,
        },
      }
    ) => {
      state.deleteTeamDialogOpen = deleteTeamDialogOpen;
    },
    setEditCourseSeatsDialogOpen: (
      state,
      {
        payload: editCourseSeatsDialogOpen = {
          open: false,
          courseId: null,
          teamId: null,
        },
      }
    ) => {
      state.editCourseSeatsDialogOpen = editCourseSeatsDialogOpen;
    },
    setCourseFilter: (state, { payload: courseFilter = 'all' }) => {
      state.courseFilter = courseFilter;
    },
    setAddAdminDialogOpen: (state, { payload: open = false }) => {
      state.addAdminDialogOpen = open;
    },
    setConfirmRemoveAdminDialog: (
      state,
      {
        payload: confirmRemoveAdminDialog = {
          open: false,
          name: '',
          userId: null,
        },
      }
    ) => {
      state.confirmRemoveAdminDialog = confirmRemoveAdminDialog;
    },
    addSelectedStudent: (
      state,
      {
        payload: selectedUser = {
          id: null,
          totalProgress: 0,
        },
      }
    ) => {
      const selectedIndex = state.transferStudents.selectedStudents.findIndex(
        ({ id }) => id === selectedUser.id
      );
      if (selectedIndex === -1) {
        state.transferStudents.selectedStudents.push(selectedUser);
      }
    },
    removeSelectedStudent: (
      state,
      {
        payload: selectedUser = {
          id: null,
          totalProgress: 0,
        },
      }
    ) => {
      const selectedIndex = state.transferStudents.selectedStudents.findIndex(
        ({ id }) => id === selectedUser.id
      );
      if (selectedIndex >= 0) {
        state.transferStudents.selectedStudents.splice(selectedIndex, 1);
      }
    },
    resetSelectedStudents: (state) => {
      state.transferStudents.selectedStudents = [];
    },
    toggleTransferDialog: (state, { payload }) => {
      state.transferStudents.open = !!payload;
    },
    setSelectedTeam: (state, { payload }) => {
      state.transferStudents.selectedTeam = payload;
    },
    setRowsPerPage: (state, { payload }) => {
      state.limit = payload;
    },
  },
});

export default companiesSlice.reducer;

export const actions = companiesSlice.actions;
export const {
  setSearch,
  setOrder,
  setOrderBy,
  setPage,
  setRowsPerPage,
  setAddTeamDialogOpen,
  setAddCourseDialogOpen,
  setAddTeamCourseDialogOpen,
  setEditCourseSeatsDialogOpen,
  setCourseFilter,
  setAddAdminDialogOpen,
  setDeleteTeamDialogOpen,
  setConfirmRemoveAdminDialog,
  addSelectedStudent,
  removeSelectedStudent,
  resetSelectedStudents,
  toggleTransferDialog,
  setSelectedTeam,
} = companiesSlice.actions;

export const selectSlice = (state) => state.companies;
export const selectSearch = (state) => state.companies.search;
export const selectTeamDialogOpen = (state) =>
  state.companies.addTeamDialogOpen;
export const selectAddTeamCourseDialogOpen = (state) =>
  state.companies.addTeamCourseDialogOpen;
export const selectAddCourseDialogOpen = (state) =>
  state.companies.addCourseDialogOpen;
export const selectDeleteTeamDialogOpen = (state) =>
  state.companies.deleteTeamDialogOpen;
export const selectCourseFilter = (state) => state.companies.courseFilter;
export const selectAddAdminDialogOpen = (state) =>
  state.companies.addAdminDialogOpen;
export const selectConfirmRemoveAdminDialog = (state) =>
  state.companies.confirmRemoveAdminDialog;
export const selectEditCourseSeatsDialogOpen = (state) =>
  state.companies.editCourseSeatsDialogOpen;
export const selectSelectedStudents = (state) =>
  state.companies.transferStudents.selectedStudents;
export const selectTransferStudents = (state) =>
  state.companies.transferStudents;
export const selectSelectedTeam = (state) => state.companies.selectedTeam;
