import 'react-app-polyfill/ie9';
import 'mdn-polyfills/Array.prototype.findIndex';
import 'promise-repeat-polyfill';
import 'polyfill-array-includes';
import 'array-flat-polyfill';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import SentryRRWeb from '@sentry/rrweb';
import TagManager from 'react-gtm-module';

import store from './app/store';
import './index.css';
import App from './App';
import Loading from 'components/Loading';
import './app/i18n';

const isProduction = process.env.REACT_APP_ENV === 'production';

if (isProduction) {
  Sentry.init({
    dsn: 'https://dbf04528046f4a519fa74ba72e700a06@o922429.ingest.sentry.io/5869437',
    integrations: [new Integrations.BrowserTracing(), new SentryRRWeb({})],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: isProduction ? 'production' : 'development',
  });
}

if (isProduction) {
  const tagManagerArgs = {
    gtmId: 'GTM-MH29RN4',
  };
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
