import { createSlice } from '@reduxjs/toolkit';

import { api } from 'app/api';

export const apiWithExamAnalysis = api.injectEndpoints({
  endpoints: (build) => ({
    getExamAnalysis: build.query({
      query: ({ session, user }) => {
        const queryString = new URLSearchParams({
          ...(session && { session }),
          ...(user && { user }),
        }).toString();
        return `/reporting?${queryString}`;
      },
    }),
    getExamAnalysisAllTrainers: build.query({
      query: () => '/users/all_trainers_reporting',
    }),
  }),
});

export const { useGetExamAnalysisQuery, useGetExamAnalysisAllTrainersQuery } =
  apiWithExamAnalysis;

const initialState = {
  session: null,
  user: null,
  exam: null,
};

export const examAnalysisSlice = createSlice({
  name: 'examAnalysis',
  initialState,
  reducers: {
    setSelectedSession: (state, { payload = null }) => {
      state.session = payload;
    },
    setSelectedUser: (state, { payload = null }) => {
      state.session = null;
      state.exam = null;
      state.user = payload;
    },
    setSelectedExam: (state, { payload = null }) => {
      state.exam = payload;
    },
  },
});

export default examAnalysisSlice.reducer;
export const { actions } = examAnalysisSlice;
export const { setSelectedSession, setSelectedUser, setSelectedExam } = actions;

export const selectExamAnalysis = (state) => state.examAnalysis;
